<template>
  <div class="bg-black text-green-600 font-mono break-all h-10 overflow-hidden text-sm">
	<span class="text-green-500" v-for="number in numbers">
		{{ number }}
	</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
	  data() {
	 return {
		numbers: [1, 2, 3, 4, 5],
	 }
	},
	 mounted(){
		this.numbers = this.generateMatrix();
		setInterval(() => {
			this.numbers = []
			this.numbers = [...this.generateMatrix()];
		}, 100);
	 },
	methods: {
		generateMatrix() {
			// generate random binary like in the movie "matrix"
			let matrix = [];
			for (let i = 0; i < 1500; i++) {
				matrix.push(Math.round(Math.random()));
			}
			return matrix;
		},
	}
},
);
</script>

<style scoped></style>