import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-4" }
const _hoisted_2 = { class: "flex items-center gap-2" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "flex items-center mt-2" }
const _hoisted_5 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_tree_item = _resolveComponent("tree-item")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: $props.tree.logo ? $props.tree.logo : '/img/icon-folder.png',
        alt: "icon",
        class: _normalizeClass([$props.tree.logo ? 'w-6 h-6' : '', "mr-2"])
      }, null, 10 /* CLASS, PROPS */, _hoisted_3),
      _createTextVNode(" " + _toDisplayString($props.tree.name), 1 /* TEXT */)
    ]),
    _createCommentVNode(" vertical line"),
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "border-r border-gray-300 border-dotted absolute top-10 bottom-4 left-7 w-px" }, null, -1 /* HOISTED */)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tree.children, (treeItem) => {
      return (_openBlock(), _createElementBlock("div", {
        key: treeItem.id,
        class: "ml-5"
      }, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("img", {
            src: treeItem.logo ? treeItem.logo : '/img/icon-folder.png',
            alt: "icon",
            class: _normalizeClass([treeItem.logo ? 'w-6 h-6' : '', "mr-2"])
          }, null, 10 /* CLASS, PROPS */, _hoisted_5),
          _createTextVNode(" " + _toDisplayString(treeItem.name), 1 /* TEXT */)
        ]),
        _createCommentVNode(" vertical line"),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "border-r border-gray-300 border-dotted absolute top-10 bottom-4 left-12 w-px" }, null, -1 /* HOISTED */)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(treeItem.children, (child) => {
          return (_openBlock(), _createBlock(_component_tree_item, {
            key: child.id,
            tree: child,
            onNewActiveTab: _cache[0] || (_cache[0] = $event => (_ctx.$emit('newActiveTab', $event)))
          }, null, 8 /* PROPS */, ["tree"]))
        }), 128 /* KEYED_FRAGMENT */))
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}