import { start3DModel } from "./stl-to-ascii";

function q(selector: string) {
    return document.querySelector(selector);
}
function qa(selector: string) {
    return document.querySelectorAll(selector);
}

export function home() {

    start3DModel();

    // color nav items portafolio 
    const items = qa('.nav-item');
    items.forEach(item => {
        item.addEventListener('click', () => {
            items.forEach(el => el.classList.remove('bg-[#A7A8AA]'));

            item.classList.add('bg-[#A7A8AA]');
        });
    });

    //ITEMS 1 - 3 NAV
    let itemEl = q('#item-1');
    if (itemEl == null) {
        console.error("element not found with selector")
        return
    }
    const imagenesPequenas = q('#imagenes-pequenas');
    const imagenGrande = q('#imagen-grande');
    const contentContainer = q('#content-container');
    const logosContainer = q('#logos-container');

    if (imagenesPequenas == null || imagenGrande == null || contentContainer == null || logosContainer == null) {
        console.error("element not found with selector", imagenesPequenas, imagenGrande, contentContainer, logosContainer)
        return
    }
    itemEl.addEventListener('click', () => {
        imagenesPequenas.classList.remove('hidden');
        imagenGrande.classList.remove('hidden');
        contentContainer.classList.add('hidden');
        logosContainer.classList.add('hidden');
    });

    q('#item-2').addEventListener('click', () => {
        if (logosContainer.classList.contains('hidden')) {
            logosContainer.classList.remove('hidden');
            logosContainer.classList.add('block');
            imagenesPequenas.classList.add('hidden');
            imagenGrande.classList.add('hidden');
            contentContainer.classList.add('hidden');
        } else {
            logosContainer.classList.add('hidden');
            logosContainer.classList.remove('block');
            imagenesPequenas.classList.remove('hidden');
            imagenGrande.classList.remove('hidden');
        }
    });

    q('#item-3').addEventListener('click', () => {
        if (contentContainer.classList.contains('hidden')) {
            contentContainer.classList.remove('hidden');
            contentContainer.classList.add('block');
            imagenesPequenas.classList.add('hidden');
            imagenGrande.classList.add('hidden');
            logosContainer.classList.add('hidden');
        } else {
            contentContainer.classList.add('hidden');
            contentContainer.classList.remove('block');
            imagenesPequenas.classList.remove('hidden');
            imagenGrande.classList.remove('hidden');
        }
    });

    //Viendo para controlar el arrastre de las ventanas
    const mainContainer = q("#container");
    let isDragging = false;

    // animation image hover
    const imgAnimation = q('#img-animation');
    const imgAnimation2 = q('#img-animation-2');


    //animation flip de las windows section
    const observedSection = q('#observed-section');
    const observerOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5
    };
    const observerCallback = (entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                flipWithWAAPI();
                observer.unobserve(entry.target);
            }
        });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    observer.observe(observedSection);

    //open window float FILE
    qa('.open-window').forEach(button => {
        button.addEventListener('click', function () {
            const targetId = this.getAttribute('data-target');
            q(`#${targetId}`).classList.remove('hidden');
        });
    });

    qa('.close-window').forEach(button => {
        button.addEventListener('click', function () {
            this.closest('.absolute').classList.add('hidden');
        });
    });

    mainContainer.addEventListener("mousedown", (e) => {
        const toggleBar = e.target.closest("[id^='toggleBar']");
        if (toggleBar) {
            isDragging = false;
            const onMouseMove = () => {
                isDragging = true;
            };
            document.addEventListener("mousemove", onMouseMove);

            //mouseup
            document.addEventListener(
                "mouseup",
                () => {
                    document.removeEventListener("mousemove", onMouseMove);
                },
                { once: true }
            );
        }
    });

    mainContainer.addEventListener("click", (e) => {
        if (isDragging) return;

        if (e.target.tagName === "BUTTON" && e.target.id.startsWith("close")) {
            // close
            const box = e.target.closest(".draggable") as HTMLElement
            const content = box.querySelector("[id^='content']") as HTMLElement;
            if (content) {
                content.style.display = "none";
            }
        }

        // toggle
        const toggleBar = e.target.closest("[id^='toggleBar']");
        if (toggleBar) {
            // Show and close ez
            const box = e.target.closest(".draggable") as HTMLElement
            const content = box.querySelector("[id^='content']") as HTMLElement;
            if (content) {
                content.style.display =
                    content.style.display === "none" ? "block" : "none";
            }
        }
    });

    // animation container up
    const hiddenElement = document.querySelector('.hidden-on-scroll');

    function checkVisibility() {
        const rect = hiddenElement?.getBoundingClientRect();
        if (rect && rect.top < window.innerHeight && rect.bottom >= 0) {
            hiddenElement?.classList.add('visible');
        }
    }

    window.addEventListener('scroll', checkVisibility);
    checkVisibility();

    // animation file to right
    const hiddenElementRight = document.querySelector('.hidden-on-scroll-right');

    const hiddenOnScrollObserver = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                hiddenElementRight?.classList.add('visible');
                observer.unobserve(hiddenElementRight!);
            }
        });
    }, { threshold: 0.1 });

    hiddenOnScrollObserver.observe(hiddenElementRight!);

    // añade y quita clases para mover la ventana
    DOMTokenList.prototype.addMany = DOMTokenList.prototype.addMany || function (classes) {
        this.add(...classes.split(' '));
    };

    DOMTokenList.prototype.removeMany = DOMTokenList.prototype.removeMany || function (classes) {
        this.remove(...classes.split(' '));
    };

    const container = document.getElementById('container');

    //inicializando
    let startX = 0;
    let startY = 0;
    let draggingStyle = "cursor-move bg-red-500 text-black";


    let currentlyDragging
    // Mousemove to move the element
    document.addEventListener("mousemove", (ev) => {
        if (currentlyDragging) {
            ev.preventDefault();
            const containerRect = container?.getBoundingClientRect();
            let newX = ev.clientX - startX - containerRect.left;
            let newY = ev.clientY - startY - containerRect.top;

            // Opcional: Restringir movimiento dentro del contenedor
            newX = Math.max(0, Math.min(newX, containerRect.width - currentlyDragging.offsetWidth));
            newY = Math.max(0, Math.min(newY, containerRect.height - currentlyDragging.offsetHeight));

            currentlyDragging.style.left = `${newX}px`;
            currentlyDragging.style.top = `${newY}px`;
        }
    }, false);

    // mouseup arrastrar y soltar la ventana 
    document.addEventListener("mouseup", (ev) => {
        if (currentlyDragging) {
            currentlyDragging.classList.remove('dragging');
            currentlyDragging.classList.removeMany(draggingStyle);
            currentlyDragging = null;
        }
    }, false);

    // mousedown para iniciar el arrastre y soltar la ventana
    container.addEventListener("mousedown", (ev) => {
        let target = ev.target.closest('.draggable');
        if (target && container?.contains(target)) {
            ev.preventDefault();
            target.classList.add('dragging');
            target.classList.addMany(draggingStyle);

            const boxRect = target.getBoundingClientRect();
            const containerRect = container.getBoundingClientRect();
            startX = ev.clientX - boxRect.left;
            startY = ev.clientY - boxRect.top;

            currentlyDragging = target;
        }
    }, false);
}


// Función para movimiento de ventanas "Animate Flip" usando WAAPI
function flipWithWAAPI() {
    const containerRect = container?.getBoundingClientRect();

    if (!containerRect) return;
    const draggableEls = document.querySelectorAll('.draggable');
    if (!draggableEls) {
        console.error("element not found with selector")
        return
    };

    draggableEls.forEach((box) => {
        // First: Get the initial position and size of the element
        const first = box.getBoundingClientRect();

        // Update position (moving the element to a new location)
        let randomX = Math.random() * (containerRect.width - box.offsetWidth);
        let randomY = Math.random() * (containerRect.height - box.offsetHeight);
        box.style.left = randomX + 'px';
        box.style.top = randomY + 'px';

        // Last: Get the final position and size of the element
        const last = box.getBoundingClientRect();

        // Invert: Calculate the translation needed to make the element appear unchanged
        const deltaX = first.left - last.left;
        const deltaY = first.top - last.top;

        // Apply the invert transform
        box.animate([
            { transform: `translate(${deltaX}px, ${deltaY}px)` },
            { transform: 'translate(0, 0)' }
        ], {
            duration: 500,
            easing: 'ease'
        });
    });
}