<template>
    <!--Tab section-->
    <div class="transition-all shrink px-2 py-1 border-t border-x rounded-t-md overflow-hidden relative z-10 cursor-pointer text-sm text-gray-700"
        :class="data.active ? 'bg-white border-blue-500 top-0' : 'bg-gray-200 border-gray-500 border-b -top-1'">
        <div class="flex md:gap-2 gap-0 justify-between items-center w-full group" @click="openTab(data.id)">
            <!-- Logo -->
            <img :src="data.logo"
                v-if="data.type=='project'|| data.type=='icon' || data.type=='logo' || data.type=='font' || data.type=='folder'"
                class="w-6" alt="logo" />

            <div class="hidden md:block truncate">
                {{ data.name }}
            </div>

            <button @click.stop="closeTab(data.id)" class="shrink-0 hover:text-red-500 px-1 py-1 hover:bg-gray-200">
                <!-- cross icon -->
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg>
            </button>
        </div>
    </div>

    <!--Information project-->

    <section v-if="data?.active" class="absolute inset-x-0 top-10 bottom-0 bg-white border border-t-2 overflow-y-auto">

        <div class="max-w-screen-xl">

            <!-- Folder General -->
            <div v-if="data.type === 'folder'">
                <div class="grid md:grid-cols-4 gap-4 mt-10 px-8" :class="data.children[0].type == 'project' ? 'grid-cols-1' : 'grid-cols-2 lg:grid-cols-6'">
                    <article v-for="treeItem in data.children" :key="treeItem.id"
                        class="flex items-center justify-center">
                        <!-- Folder -->
                        <button v-if="treeItem.type === 'folder'"
                            class="cursor-pointer w-full flex flex-col items-center hover:bg-blue-600 py-4"
                            @click="$emit('newActiveTab', treeItem)">
                            <img src="/img/icon-folder.png" alt="img" class="w-12 h-12 mb-4" />
                            <p>{{ treeItem.name }}</p>
                        </button>
                        <!--Folder Brands-->
                        <button v-else-if="treeItem.type === 'logo' || treeItem.type === 'font'"
                            class="flex flex-col items-center hover:bg-blue-600 py-6 w-full"
                            @click="$emit('newActiveTab', treeItem)">
                            <img :src="treeItem.image" alt="img" class="w-20 h-20 mb-4" />
                            <p class="whitespace-nowrap text-sm">{{ treeItem.name }}</p>
                        </button>
                        <!-- Projects -->
                        <button v-if="treeItem.type === 'project'"
                            class="flex flex-col items-center hover:bg-blue-600 px-2 py-2 hover:text-white"
                            @click="$emit('newActiveTab', treeItem)">
                            <video :src="treeItem.image" autoplay loop muted playsinline
                                class="w-56 h-40 object-contain border-2 border-gray-200 p-2" alt="video"></video>
                            <p class="text-center mt-2">{{ treeItem.video }}</p>
                        </button>

                        <!-- Icons Wm -->
                        <button v-else-if="treeItem.type === 'icon'"
                            class="flex flex-col items-center cursor-pointer hover:bg-blue-600 py-6 w-full"
                            @click="$emit('newActiveTab', treeItem)">
                            <img :src="treeItem.image"
                                class="w-24 h-24 object-contain mb-2 border-2 border-gray-200 p-2" alt="icon" />
                            <p class="text-center mt-2">{{ treeItem.name }}</p>
                        </button>
                    </article>
                </div>
            </div>

            <!-- Fonts -->
            <div v-else-if="data.type === 'font'" class="px-4 sm:px-8 mt-8 mb-6">
                <h1 class="text-2xl font-semibold md:text-left text-center lg:px-4 mb-4">{{ data.name }}</h1>
                <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                    <div v-for="font in data.children" :key="font.id" class="flex flex-col w-56 mx-auto">
                        <div class="h-32 flex items-center border-4 border-gray-300 bg-white">
                            <img :src="font.logo" :alt="'Espécimen de ' + font.name" class="h-full w-full object-cover" />
                        </div>

                        <div class="flex w-full mt-3 text-base justify-between items-center">
                            <p class="text-left">{{ font.name }}</p>
                            <a :href="font.url" class="w-14 bg-gray-200 border border-gray-500 px-1 text-xs 
                            hover:bg-gray-600 hover:text-white">Obtener</a>
                        </div>
                    </div>
                </div>
            </div>

            <!-- project -->
            <div v-else-if="data?.type == 'project'" class="px-2 lg:px-10 flex flex-col mb-10 mt-2 lg:mt-10">

                <div class="flex md:flex-row gap-6 relative">
                    <div class="w-full border border-dotted border-b-2 overflow-hidden">
                        <video :src="data.image" autoplay loop muted playsinline class="lg:h-72 w-full"></video>
                    </div>
                    <!-- mobile verison -->
                    <div class="absolute w-16 right-0 bottom-0 lg:relative lg:w-64  border border-dotted border-b-2 mx-auto md:mt-0 mt-10 shadow lg:shadow-none">
                        <video :src="data.image_phone" autoplay loop muted playsinline class="lg:h-72 mx-auto"></video>
                    </div>
                </div>

                <div class="flex flex-col md:flex-row gap-6 mt-8 border border-dotted px-2 lg:px-4 py-2 lg:py-4">
                    <div class="w-full">
                        <p class="font-bold text-2xl">{{ data.name }}</p>
                        <p class="text-gray-800 text-sm lg:text-base lg:text-justify mt-2 leading-loose">{{ data.info }}</p>
                    </div>

                    <!-- Time load -->
                    <div
                        class="w-full md:w-64 text-center mx-auto border border-dotted px-4 py-4 flex flex-col justify-center">
                        <p class="text-2xl font-semibold">{{ data.time_load }}</p>
                        <p class="text-sm mb-4">Tiempo de carga</p>

                        <p class="text-2xl font-semibold">{{ data.weight }}</p>
                        <p class="text-sm mb-4">Peso</p>

                        <img :src="data.technologies_image" class="h-8 mx-auto object-contain" alt="technology-image" />
                        <p class="text-sm mt-2">Tecnologías</p>
                    </div>
                </div>

                <!--Project type-->
                <div class="flex w-full mt-10 mx-auto gap-4 mb-6 items-center justify-between">

                    <div class="flex flex-col md:flex-row gap-4">
                        <div class="bg-gray-200 border border-gray-500 flex items-center gap-2 p-2">
                            <!--Calendar Icon-->
                            <img src="/img/calendar-icon.png" alt="calendar">
                            {{ data.project_type }}
                        </div>
                        <div class="bg-gray-200 border border-gray-500 flex items-center gap-2 p-2">
                            <!--Calendar Icon 2-->
                            <img src="/img/calendar-icon.png" alt="calendar">
                            {{ data.year }}
                        </div>
                    </div>

                    <div class="flex flex-col md:flex-row gap-4">

                        <!-- Back-->
                        <button @click="$emit('back', data.back)" v-if="data.back" class="w-24">
                            <div class="flex items-center gap-2">

                                <!-- Arrow left icon -->
                                <svg class="ml-2 rotate-180" xmlns="http://www.w3.org/2000/svg" width="36" height="36"
                                    viewBox="0 0 36 36" fill="none">
                                    <rect x="35" y="1" width="34" height="34" transform="rotate(90 35 1)"
                                        fill="url(#paint0_linear_2011_1743)" stroke="#A2A09F" stroke-width="2" />
                                    <path d="M12.521 9.39062L23.4775 17.9993L12.521 26.608" stroke="black"
                                        stroke-width="2.5" />
                                    <defs>
                                        <linearGradient id="paint0_linear_2011_1743" x1="54" y1="0" x2="54" y2="36"
                                            gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#FDFDFD" />
                                            <stop offset="1" stop-color="#BFBFBF" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                                <!-- Imagen -->
                                <img :src="data.back.logo" class="w-12 h-12 border-2 border-gray p-2 object-contain"
                                    alt="icon" />

                            </div>
                        </button>

                        <!--Next-->
                        <button @click="$emit('next', data.next)" v-if="data.next">
                            <div class="flex items-center">
                                <!-- Imagen -->
                                <img :src="data.next.logo" class="w-12 h-12 border-2 border-gray p-2 object-contain"
                                    alt="icon" />

                                <!-- Arrow right icon -->
                                <svg class="ml-2" xmlns="http://www.w3.org/2000/svg" width="36" height="36"
                                    viewBox="0 0 36 36" fill="none">
                                    <rect x="35" y="1" width="34" height="34" transform="rotate(90 35 1)"
                                        fill="url(#paint0_linear_2011_1743)" stroke="#A2A09F" stroke-width="2" />
                                    <path d="M12.521 9.39062L23.4775 17.9993L12.521 26.608" stroke="black"
                                        stroke-width="2.5" />
                                    <defs>
                                        <linearGradient id="paint0_linear_2011_1743" x1="54" y1="0" x2="54" y2="36"
                                            gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#FDFDFD" />
                                            <stop offset="1" stop-color="#BFBFBF" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                        </button>

                    </div>
                </div>
            </div>

            <!-- Tech Brands/ Posters/ Merchandising/ Information/ Logotypes-->
            <div v-else class="px-4 sm:px-8 mt-8 mb-6">
                <h1 class="text-2xl font-semibold md:text-left text-center px-0 mb-4">{{ data.name }}</h1>
                <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 justify-center">
                    <div v-for="icon in data.children" :key="icon.id" class="flex flex-col mx-auto w-full">
                        <a :href="icon.logo" :download="icon.selectedFile || icon.variants[0].url" target="_blank">
                            <div class="h-24 w-full mx-auto flex items-center border-4 border-gray-300 bg-white cursor-pointer">
                                <img :src="icon.logo" alt="Logo" class="h-24 object-contain p-2 w-24 mx-auto" />
                            </div>
                        </a>

                        <div class="flex w-full mt-3 text-xs justify-between items-center w-full">
                            <div class="flex items-center gap-2 justify-between w-full">
                                <!-- formats img-->
                                <select :id="'icon--selector--'+icon.id"
                                    class="border border-gray-400 rounded px-1 py-1 text-xs grow w-full">
                                    <option v-for="variant in icon.variants" :key="variant.name" :value="variant.url">
                                        {{ icon.name }}.{{ variant.name }}
                                    </option>
                                </select>
                                <button
                                    class="bg-gray-200 border border-gray-500 px-1 py-1  hover:bg-gray-600 hover:text-white"
                                    @click="downloadSelectedFile(icon, '#icon--selector--'+icon.id)">
                                    <!-- icon download -->
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke-width="1.5" stroke="currentColor" class="size-4">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                            d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                    </svg>

                                </button>
                            </div>
                            <p class="text-left ml-auto hidden">{{ icon.weight }}</p>
                        </div>
                    </div>
                </div>
            </div>


            <!--Icon Wm-->
            <div v-if="data?.type == 'icon'" class="h-[70vh] flex justify-center items-center">
                <div class="flex flex-col">
                    <img :src="data.image" class="w-64 object-contain" />
                </div>
            </div>
        </div>
    </section>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        data: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            selectedFile: null 
        };
    },
    methods: {
        closeTab(id: string) {
            this.$emit('close-tab', id);
        },
        openTab(id: string) {
            this.$emit('open-tab', id);
        },
        downloadSelectedFile(icon: any, selector: string) {
            const selectedFile = (document.querySelector(selector) as HTMLSelectElement).value;
            const a = document.createElement('a') as HTMLAnchorElement;
            a.href = selectedFile;
            a.setAttribute("download", "");
            a.click();
        }
    }
});
</script>