import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "border p-4 bg-black/50 rounded-md" }
const _hoisted_2 = { class: "text-5xl mt-4" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("label", {
      for: "",
      class: "text-center text-gray-400 text-xs"
    }, "Edit me: in /src/assets/ts/components/counter.vue", -1 /* HOISTED */)),
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.count), 1 /* TEXT */),
    _createElementVNode("button", {
      class: "btn btn-primary mt-4",
      onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.increment && _ctx.increment(...args)))
    }, "Increment")
  ]))
}