import { createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "hidden md:block truncate" }
const _hoisted_3 = {
  key: 0,
  class: "absolute inset-x-0 top-10 bottom-0 bg-white border border-t-2 overflow-y-auto"
}
const _hoisted_4 = { class: "max-w-screen-xl" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "whitespace-nowrap text-sm" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "text-center mt-2" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "text-center mt-2" }
const _hoisted_16 = { class: "px-4 sm:px-8 mt-8 mb-6" }
const _hoisted_17 = { class: "text-2xl font-semibold md:text-left text-center lg:px-4 mb-4" }
const _hoisted_18 = { class: "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6" }
const _hoisted_19 = { class: "h-32 flex items-center border-4 border-gray-300 bg-white" }
const _hoisted_20 = ["src", "alt"]
const _hoisted_21 = { class: "flex w-full mt-3 text-base justify-between items-center" }
const _hoisted_22 = { class: "text-left" }
const _hoisted_23 = ["href"]
const _hoisted_24 = { class: "px-2 lg:px-10 flex flex-col mb-10 mt-2 lg:mt-10" }
const _hoisted_25 = { class: "flex md:flex-row gap-6 relative" }
const _hoisted_26 = { class: "w-full border border-dotted border-b-2 overflow-hidden" }
const _hoisted_27 = ["src"]
const _hoisted_28 = { class: "absolute w-16 right-0 bottom-0 lg:relative lg:w-64 border border-dotted border-b-2 mx-auto md:mt-0 mt-10 shadow lg:shadow-none" }
const _hoisted_29 = ["src"]
const _hoisted_30 = { class: "flex flex-col md:flex-row gap-6 mt-8 border border-dotted px-2 lg:px-4 py-2 lg:py-4" }
const _hoisted_31 = { class: "w-full" }
const _hoisted_32 = { class: "font-bold text-2xl" }
const _hoisted_33 = { class: "text-gray-800 text-sm lg:text-base lg:text-justify mt-2 leading-loose" }
const _hoisted_34 = { class: "w-full md:w-64 text-center mx-auto border border-dotted px-4 py-4 flex flex-col justify-center" }
const _hoisted_35 = { class: "text-2xl font-semibold" }
const _hoisted_36 = { class: "text-2xl font-semibold" }
const _hoisted_37 = ["src"]
const _hoisted_38 = { class: "flex w-full mt-10 mx-auto gap-4 mb-6 items-center justify-between" }
const _hoisted_39 = { class: "flex flex-col md:flex-row gap-4" }
const _hoisted_40 = { class: "bg-gray-200 border border-gray-500 flex items-center gap-2 p-2" }
const _hoisted_41 = { class: "bg-gray-200 border border-gray-500 flex items-center gap-2 p-2" }
const _hoisted_42 = { class: "flex flex-col md:flex-row gap-4" }
const _hoisted_43 = { class: "flex items-center gap-2" }
const _hoisted_44 = ["src"]
const _hoisted_45 = { class: "flex items-center" }
const _hoisted_46 = ["src"]
const _hoisted_47 = { class: "px-4 sm:px-8 mt-8 mb-6" }
const _hoisted_48 = { class: "text-2xl font-semibold md:text-left text-center px-0 mb-4" }
const _hoisted_49 = { class: "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 justify-center" }
const _hoisted_50 = ["href", "download"]
const _hoisted_51 = { class: "h-24 w-full mx-auto flex items-center border-4 border-gray-300 bg-white cursor-pointer" }
const _hoisted_52 = ["src"]
const _hoisted_53 = { class: "flex w-full mt-3 text-xs justify-between items-center w-full" }
const _hoisted_54 = { class: "flex items-center gap-2 justify-between w-full" }
const _hoisted_55 = ["id"]
const _hoisted_56 = ["value"]
const _hoisted_57 = ["onClick"]
const _hoisted_58 = { class: "text-left ml-auto hidden" }
const _hoisted_59 = {
  key: 4,
  class: "h-[70vh] flex justify-center items-center"
}
const _hoisted_60 = { class: "flex flex-col" }
const _hoisted_61 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("Tab section"),
    _createElementVNode("div", {
      class: _normalizeClass(["transition-all shrink px-2 py-1 border-t border-x rounded-t-md overflow-hidden relative z-10 cursor-pointer text-sm text-gray-700", _ctx.data.active ? 'bg-white border-blue-500 top-0' : 'bg-gray-200 border-gray-500 border-b -top-1'])
    }, [
      _createElementVNode("div", {
        class: "flex md:gap-2 gap-0 justify-between items-center w-full group",
        onClick: _cache[1] || (_cache[1] = $event => (_ctx.openTab(_ctx.data.id)))
      }, [
        _createCommentVNode(" Logo "),
        (_ctx.data.type=='project'|| _ctx.data.type=='icon' || _ctx.data.type=='logo' || _ctx.data.type=='font' || _ctx.data.type=='folder')
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.data.logo,
              class: "w-6",
              alt: "logo"
            }, null, 8 /* PROPS */, _hoisted_1))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.data.name), 1 /* TEXT */),
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.closeTab(_ctx.data.id)), ["stop"])),
          class: "shrink-0 hover:text-red-500 px-1 py-1 hover:bg-gray-200"
        }, [
          _createCommentVNode(" cross icon "),
          _cache[4] || (_cache[4] = _createElementVNode("svg", {
            xmlns: "http://www.w3.org/2000/svg",
            fill: "none",
            viewBox: "0 0 24 24",
            "stroke-width": "1.5",
            stroke: "currentColor",
            class: "w-5 h-5"
          }, [
            _createElementVNode("path", {
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              d: "M6 18 18 6M6 6l12 12"
            })
          ], -1 /* HOISTED */))
        ])
      ])
    ], 2 /* CLASS */),
    _createCommentVNode("Information project"),
    (_ctx.data?.active)
      ? (_openBlock(), _createElementBlock("section", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createCommentVNode(" Folder General "),
            (_ctx.data.type === 'folder')
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["grid md:grid-cols-4 gap-4 mt-10 px-8", _ctx.data.children[0].type == 'project' ? 'grid-cols-1' : 'grid-cols-2 lg:grid-cols-6'])
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.children, (treeItem) => {
                      return (_openBlock(), _createElementBlock("article", {
                        key: treeItem.id,
                        class: "flex items-center justify-center"
                      }, [
                        _createCommentVNode(" Folder "),
                        (treeItem.type === 'folder')
                          ? (_openBlock(), _createElementBlock("button", {
                              key: 0,
                              class: "cursor-pointer w-full flex flex-col items-center hover:bg-blue-600 py-4",
                              onClick: $event => (_ctx.$emit('newActiveTab', treeItem))
                            }, [
                              _cache[5] || (_cache[5] = _createElementVNode("img", {
                                src: "/img/icon-folder.png",
                                alt: "img",
                                class: "w-12 h-12 mb-4"
                              }, null, -1 /* HOISTED */)),
                              _createElementVNode("p", null, _toDisplayString(treeItem.name), 1 /* TEXT */)
                            ], 8 /* PROPS */, _hoisted_6))
                          : (treeItem.type === 'logo' || treeItem.type === 'font')
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createCommentVNode("Folder Brands"),
                                _createElementVNode("button", {
                                  class: "flex flex-col items-center hover:bg-blue-600 py-6 w-full",
                                  onClick: $event => (_ctx.$emit('newActiveTab', treeItem))
                                }, [
                                  _createElementVNode("img", {
                                    src: treeItem.image,
                                    alt: "img",
                                    class: "w-20 h-20 mb-4"
                                  }, null, 8 /* PROPS */, _hoisted_8),
                                  _createElementVNode("p", _hoisted_9, _toDisplayString(treeItem.name), 1 /* TEXT */)
                                ], 8 /* PROPS */, _hoisted_7)
                              ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
                            : _createCommentVNode("v-if", true),
                        _createCommentVNode(" Projects "),
                        (treeItem.type === 'project')
                          ? (_openBlock(), _createElementBlock("button", {
                              key: 2,
                              class: "flex flex-col items-center hover:bg-blue-600 px-2 py-2 hover:text-white",
                              onClick: $event => (_ctx.$emit('newActiveTab', treeItem))
                            }, [
                              _createElementVNode("video", {
                                src: treeItem.image,
                                autoplay: "",
                                loop: "",
                                muted: "",
                                playsinline: "",
                                class: "w-56 h-40 object-contain border-2 border-gray-200 p-2",
                                alt: "video"
                              }, null, 8 /* PROPS */, _hoisted_11),
                              _createElementVNode("p", _hoisted_12, _toDisplayString(treeItem.video), 1 /* TEXT */)
                            ], 8 /* PROPS */, _hoisted_10))
                          : (treeItem.type === 'icon')
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                                _createCommentVNode(" Icons Wm "),
                                _createElementVNode("button", {
                                  class: "flex flex-col items-center cursor-pointer hover:bg-blue-600 py-6 w-full",
                                  onClick: $event => (_ctx.$emit('newActiveTab', treeItem))
                                }, [
                                  _createElementVNode("img", {
                                    src: treeItem.image,
                                    class: "w-24 h-24 object-contain mb-2 border-2 border-gray-200 p-2",
                                    alt: "icon"
                                  }, null, 8 /* PROPS */, _hoisted_14),
                                  _createElementVNode("p", _hoisted_15, _toDisplayString(treeItem.name), 1 /* TEXT */)
                                ], 8 /* PROPS */, _hoisted_13)
                              ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
                            : _createCommentVNode("v-if", true)
                      ]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ], 2 /* CLASS */)
                ]))
              : (_ctx.data.type === 'font')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createCommentVNode(" Fonts "),
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("h1", _hoisted_17, _toDisplayString(_ctx.data.name), 1 /* TEXT */),
                      _createElementVNode("div", _hoisted_18, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.children, (font) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: font.id,
                            class: "flex flex-col w-56 mx-auto"
                          }, [
                            _createElementVNode("div", _hoisted_19, [
                              _createElementVNode("img", {
                                src: font.logo,
                                alt: 'Espécimen de ' + font.name,
                                class: "h-full w-full object-cover"
                              }, null, 8 /* PROPS */, _hoisted_20)
                            ]),
                            _createElementVNode("div", _hoisted_21, [
                              _createElementVNode("p", _hoisted_22, _toDisplayString(font.name), 1 /* TEXT */),
                              _createElementVNode("a", {
                                href: font.url,
                                class: "w-14 bg-gray-200 border border-gray-500 px-1 text-xs hover:bg-gray-600 hover:text-white"
                              }, "Obtener", 8 /* PROPS */, _hoisted_23)
                            ])
                          ]))
                        }), 128 /* KEYED_FRAGMENT */))
                      ])
                    ])
                  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
                : (_ctx.data?.type == 'project')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      _createCommentVNode(" project "),
                      _createElementVNode("div", _hoisted_24, [
                        _createElementVNode("div", _hoisted_25, [
                          _createElementVNode("div", _hoisted_26, [
                            _createElementVNode("video", {
                              src: _ctx.data.image,
                              autoplay: "",
                              loop: "",
                              muted: "",
                              playsinline: "",
                              class: "lg:h-72 w-full"
                            }, null, 8 /* PROPS */, _hoisted_27)
                          ]),
                          _createCommentVNode(" mobile verison "),
                          _createElementVNode("div", _hoisted_28, [
                            _createElementVNode("video", {
                              src: _ctx.data.image_phone,
                              autoplay: "",
                              loop: "",
                              muted: "",
                              playsinline: "",
                              class: "lg:h-72 mx-auto"
                            }, null, 8 /* PROPS */, _hoisted_29)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_30, [
                          _createElementVNode("div", _hoisted_31, [
                            _createElementVNode("p", _hoisted_32, _toDisplayString(_ctx.data.name), 1 /* TEXT */),
                            _createElementVNode("p", _hoisted_33, _toDisplayString(_ctx.data.info), 1 /* TEXT */)
                          ]),
                          _createCommentVNode(" Time load "),
                          _createElementVNode("div", _hoisted_34, [
                            _createElementVNode("p", _hoisted_35, _toDisplayString(_ctx.data.time_load), 1 /* TEXT */),
                            _cache[6] || (_cache[6] = _createElementVNode("p", { class: "text-sm mb-4" }, "Tiempo de carga", -1 /* HOISTED */)),
                            _createElementVNode("p", _hoisted_36, _toDisplayString(_ctx.data.weight), 1 /* TEXT */),
                            _cache[7] || (_cache[7] = _createElementVNode("p", { class: "text-sm mb-4" }, "Peso", -1 /* HOISTED */)),
                            _createElementVNode("img", {
                              src: _ctx.data.technologies_image,
                              class: "h-8 mx-auto object-contain",
                              alt: "technology-image"
                            }, null, 8 /* PROPS */, _hoisted_37),
                            _cache[8] || (_cache[8] = _createElementVNode("p", { class: "text-sm mt-2" }, "Tecnologías", -1 /* HOISTED */))
                          ])
                        ]),
                        _createCommentVNode("Project type"),
                        _createElementVNode("div", _hoisted_38, [
                          _createElementVNode("div", _hoisted_39, [
                            _createElementVNode("div", _hoisted_40, [
                              _createCommentVNode("Calendar Icon"),
                              _cache[9] || (_cache[9] = _createElementVNode("img", {
                                src: "/img/calendar-icon.png",
                                alt: "calendar"
                              }, null, -1 /* HOISTED */)),
                              _createTextVNode(" " + _toDisplayString(_ctx.data.project_type), 1 /* TEXT */)
                            ]),
                            _createElementVNode("div", _hoisted_41, [
                              _createCommentVNode("Calendar Icon 2"),
                              _cache[10] || (_cache[10] = _createElementVNode("img", {
                                src: "/img/calendar-icon.png",
                                alt: "calendar"
                              }, null, -1 /* HOISTED */)),
                              _createTextVNode(" " + _toDisplayString(_ctx.data.year), 1 /* TEXT */)
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_42, [
                            _createCommentVNode(" Back"),
                            (_ctx.data.back)
                              ? (_openBlock(), _createElementBlock("button", {
                                  key: 0,
                                  onClick: _cache[2] || (_cache[2] = $event => (_ctx.$emit('back', _ctx.data.back))),
                                  class: "w-24"
                                }, [
                                  _createElementVNode("div", _hoisted_43, [
                                    _createCommentVNode(" Arrow left icon "),
                                    _cache[11] || (_cache[11] = _createStaticVNode("<svg class=\"ml-2 rotate-180\" xmlns=\"http://www.w3.org/2000/svg\" width=\"36\" height=\"36\" viewBox=\"0 0 36 36\" fill=\"none\"><rect x=\"35\" y=\"1\" width=\"34\" height=\"34\" transform=\"rotate(90 35 1)\" fill=\"url(#paint0_linear_2011_1743)\" stroke=\"#A2A09F\" stroke-width=\"2\"></rect><path d=\"M12.521 9.39062L23.4775 17.9993L12.521 26.608\" stroke=\"black\" stroke-width=\"2.5\"></path><defs><linearGradient id=\"paint0_linear_2011_1743\" x1=\"54\" y1=\"0\" x2=\"54\" y2=\"36\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#FDFDFD\"></stop><stop offset=\"1\" stop-color=\"#BFBFBF\"></stop></linearGradient></defs></svg>", 1)),
                                    _createCommentVNode(" Imagen "),
                                    _createElementVNode("img", {
                                      src: _ctx.data.back.logo,
                                      class: "w-12 h-12 border-2 border-gray p-2 object-contain",
                                      alt: "icon"
                                    }, null, 8 /* PROPS */, _hoisted_44)
                                  ])
                                ]))
                              : _createCommentVNode("v-if", true),
                            _createCommentVNode("Next"),
                            (_ctx.data.next)
                              ? (_openBlock(), _createElementBlock("button", {
                                  key: 1,
                                  onClick: _cache[3] || (_cache[3] = $event => (_ctx.$emit('next', _ctx.data.next)))
                                }, [
                                  _createElementVNode("div", _hoisted_45, [
                                    _createCommentVNode(" Imagen "),
                                    _createElementVNode("img", {
                                      src: _ctx.data.next.logo,
                                      class: "w-12 h-12 border-2 border-gray p-2 object-contain",
                                      alt: "icon"
                                    }, null, 8 /* PROPS */, _hoisted_46),
                                    _createCommentVNode(" Arrow right icon "),
                                    _cache[12] || (_cache[12] = _createStaticVNode("<svg class=\"ml-2\" xmlns=\"http://www.w3.org/2000/svg\" width=\"36\" height=\"36\" viewBox=\"0 0 36 36\" fill=\"none\"><rect x=\"35\" y=\"1\" width=\"34\" height=\"34\" transform=\"rotate(90 35 1)\" fill=\"url(#paint0_linear_2011_1743)\" stroke=\"#A2A09F\" stroke-width=\"2\"></rect><path d=\"M12.521 9.39062L23.4775 17.9993L12.521 26.608\" stroke=\"black\" stroke-width=\"2.5\"></path><defs><linearGradient id=\"paint0_linear_2011_1743\" x1=\"54\" y1=\"0\" x2=\"54\" y2=\"36\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#FDFDFD\"></stop><stop offset=\"1\" stop-color=\"#BFBFBF\"></stop></linearGradient></defs></svg>", 1))
                                  ])
                                ]))
                              : _createCommentVNode("v-if", true)
                          ])
                        ])
                      ])
                    ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                      _createCommentVNode(" Tech Brands/ Posters/ Merchandising/ Information/ Logotypes"),
                      _createElementVNode("div", _hoisted_47, [
                        _createElementVNode("h1", _hoisted_48, _toDisplayString(_ctx.data.name), 1 /* TEXT */),
                        _createElementVNode("div", _hoisted_49, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.children, (icon) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: icon.id,
                              class: "flex flex-col mx-auto w-full"
                            }, [
                              _createElementVNode("a", {
                                href: icon.logo,
                                download: icon.selectedFile || icon.variants[0].url,
                                target: "_blank"
                              }, [
                                _createElementVNode("div", _hoisted_51, [
                                  _createElementVNode("img", {
                                    src: icon.logo,
                                    alt: "Logo",
                                    class: "h-24 object-contain p-2 w-24 mx-auto"
                                  }, null, 8 /* PROPS */, _hoisted_52)
                                ])
                              ], 8 /* PROPS */, _hoisted_50),
                              _createElementVNode("div", _hoisted_53, [
                                _createElementVNode("div", _hoisted_54, [
                                  _createCommentVNode(" formats img"),
                                  _createElementVNode("select", {
                                    id: 'icon--selector--'+icon.id,
                                    class: "border border-gray-400 rounded px-1 py-1 text-xs grow w-full"
                                  }, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(icon.variants, (variant) => {
                                      return (_openBlock(), _createElementBlock("option", {
                                        key: variant.name,
                                        value: variant.url
                                      }, _toDisplayString(icon.name) + "." + _toDisplayString(variant.name), 9 /* TEXT, PROPS */, _hoisted_56))
                                    }), 128 /* KEYED_FRAGMENT */))
                                  ], 8 /* PROPS */, _hoisted_55),
                                  _createElementVNode("button", {
                                    class: "bg-gray-200 border border-gray-500 px-1 py-1 hover:bg-gray-600 hover:text-white",
                                    onClick: $event => (_ctx.downloadSelectedFile(icon, '#icon--selector--'+icon.id))
                                  }, [
                                    _createCommentVNode(" icon download "),
                                    _cache[13] || (_cache[13] = _createElementVNode("svg", {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      fill: "none",
                                      viewBox: "0 0 24 24",
                                      "stroke-width": "1.5",
                                      stroke: "currentColor",
                                      class: "size-4"
                                    }, [
                                      _createElementVNode("path", {
                                        "stroke-linecap": "round",
                                        "stroke-linejoin": "round",
                                        d: "M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                                      })
                                    ], -1 /* HOISTED */))
                                  ], 8 /* PROPS */, _hoisted_57)
                                ]),
                                _createElementVNode("p", _hoisted_58, _toDisplayString(icon.weight), 1 /* TEXT */)
                              ])
                            ]))
                          }), 128 /* KEYED_FRAGMENT */))
                        ])
                      ])
                    ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */)),
            _createCommentVNode("Icon Wm"),
            (_ctx.data?.type == 'icon')
              ? (_openBlock(), _createElementBlock("div", _hoisted_59, [
                  _createElementVNode("div", _hoisted_60, [
                    _createElementVNode("img", {
                      src: _ctx.data.image,
                      class: "w-64 object-contain"
                    }, null, 8 /* PROPS */, _hoisted_61)
                  ])
                ]))
              : _createCommentVNode("v-if", true)
          ])
        ]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}