let elementsSetup: HTMLElement[] = []

export function setup() {
    // use interceptor API to add a class when the element is in view
    if (!window.observer){
        let obs = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    isIntersecting(entry.target as HTMLElement);
                }
            });
        });
        window.observer = obs;
    }

    // get all elements with data-intro attribute
    let elements = document.querySelectorAll('[data-intro]');
    elements.forEach(addAnimation);
}

function isIntersecting(element: HTMLElement){
    let animation = element.dataset.intro || 'fade-in';
    let delayStr = element.dataset.introDelay || '0';
    let delay = 0;
    if (delayStr) {
        delay = parseInt(delayStr);
    }
    let duration = element.dataset.introDuration;
    if (duration) {
        duration = parseInt(duration)/1000 + 's';
    }

    setTimeout(() => {
        element.classList.remove('opacity-0');
        element.classList.add(animation);

        // if (delay) {
        //     element.style.animationDelay = delay + 'ms';
        // }
        if (duration) {
            element.style.animationDuration = duration;
        }
        // // remove observer after animation
        // window.observer.unobserve(element);
        // // remove from elementsSetup
        // elementsSetup = elementsSetup.filter(el => el !== element);
    }, delay);
}


function addAnimation(element: Element) {
    let el = element as HTMLElement;
    for (let i = 0; i < elementsSetup.length; i++) {
        // elementsSetup[i].classList.remove('opacity-0');
        // let sameClass = JSON.stringify(elementsSetup[i].classList) === JSON.stringify(element.classList);
        let sameIntro = elementsSetup[i].dataset.intro === el.dataset.intro
        let sameContent = elementsSetup[i].textContent === el.textContent
        let sameClasses = elementsSetup[i].classList === el.classList
        let sameId = elementsSetup[i].id == el.id
        let sameHtml = elementsSetup[i].innerHTML === el.innerHTML
        if (sameIntro && sameContent && sameHtml && sameClasses && sameId){ 
            element.classList.remove('opacity-0');
            return;
        }
        // if (sameClass){
        //     console.log(element, elementsSetup[i], sameClass, sameIntro, sameContent)
        // }
    }
    element.classList.add('opacity-0');
    element.classList.remove('hidden');
    elementsSetup.push(el);
    window.observer.observe(element);
}