import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex gap-2 p-2 overflow-x-auto" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_tab = _resolveComponent("tab")

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.tabs, (tab) => {
        return (_openBlock(), _createBlock(_component_tab, {
          data: tab,
          key: tab.id,
          onCloseTab: $options.removeTab,
          onOpenTab: $options.openTab,
          onNext: _cache[0] || (_cache[0] = $event => ($options.handleNext($event))),
          onBack: _cache[1] || (_cache[1] = $event => ($options.handleBack($event))),
          onNewActiveTab: _cache[2] || (_cache[2] = $event => (_ctx.$emit('newActiveTab', $event)))
        }, null, 8 /* PROPS */, ["data", "onCloseTab", "onOpenTab"]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}