import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-[url(/img/bg-xp.webp)] bg-cover" }
const _hoisted_2 = { class: "max-w-screen-xl mx-auto pt-4 pb-4 px-2 shadow-lg" }
const _hoisted_3 = { class: "flex flex-col lg:flex-row" }
const _hoisted_4 = { class: "bg-gray-300 border border-gray-950 px-4 py-2.5 flex items-center justify-between" }
const _hoisted_5 = { class: "overflow-y-auto max-h-[80vh] absolute left-0 top-13 h-[36vh] bg-white border shadow z-20 w-full" }
const _hoisted_6 = { class: "lg:h-[50vh] bg-white border-gray-200 flex flex-col" }
const _hoisted_7 = { class: "overflow-y-auto max-h-[100vh] hidden lg:block relative" }
const _hoisted_8 = { class: "flex flex-col border-t border-gray-300 bg-gray-300" }
const _hoisted_9 = { class: "text-sm px-2 py-1 border border-gray-400 m-2" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_tree = _resolveComponent("tree")
  const _component_tab_manager = _resolveComponent("tab-manager")

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createCommentVNode(" Top bar"),
      _cache[7] || (_cache[7] = _createStaticVNode("<div class=\"bg-[#394DCD] px-2 flex justify-between items-center\"><div class=\"flex items-center\"><img src=\"/img/icon-folder-2.png\" alt=\"\"><p class=\"p-2 text-white\">C:\\WeMakeeeee Portafolio</p></div><div class=\"flex space-x-1\"><button class=\"w-5 h-6 bg-gray-300 border text-black\"><!--Minimize SVG--><svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" fill=\"black\" class=\"size-4\"><path d=\"M22 20H2c-0.55 0-1-0.45-1-1s0.45-1 1-1h20c0.55 0 1 0.45 1 1s-0.45 1-1 1z\"></path></svg></button><button class=\"w-6 h-6 bg-gray-300 border text-black flex items-center justify-center\"><!--Maximize SVG--><svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" fill=\"black\" class=\"w-4 h-4\"><path d=\"M22 2H2C0.9 2 0 2.9 0 4v16c0 1.1 0.9 2 2 2h20c1.1 0 2-0.9 2-2V4c0-1.1-0.9-2-2-2zm0 18c0 0.55-0.45 1-1 1H3c-0.55 0-1-0.45-1-1V9h20v11z\"></path></svg></button><button class=\"w-4 h-6 bg-gray-300 border text-black flex items-center justify-center\"><!--Close SVG--><svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" stroke=\"black\" fill=\"black\" stroke-width=\"1.5\"><path stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M6 18 18 6M6 6l12 12\"></path></svg></button></div></div>", 1)),
      _createCommentVNode("Tree side"),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          style: _normalizeStyle('width: ' + $data.width + '%'),
          class: _normalizeClass(["relative", $data.isMobile ? '' : ' shrink-0'])
        }, [
          _createElementVNode("div", _hoisted_4, [
            _cache[4] || (_cache[4] = _createTextVNode(" Nuestros proyectos ")),
            _createElementVNode("button", {
              class: "lg:hidden",
              onClick: _cache[0] || (_cache[0] = $event => ($data.showMenu = !$data.showMenu))
            }, _cache[3] || (_cache[3] = [
              _createElementVNode("svg", {
                xmlns: "http://www.w3.org/2000/svg",
                width: "36",
                height: "36",
                viewBox: "0 0 36 36",
                fill: "none"
              }, [
                _createElementVNode("rect", {
                  y: "8",
                  width: "24",
                  height: "2",
                  fill: "black"
                }),
                _createElementVNode("rect", {
                  y: "17",
                  width: "24",
                  height: "2",
                  fill: "black"
                }),
                _createElementVNode("rect", {
                  y: "26",
                  width: "24",
                  height: "2",
                  fill: "black"
                })
              ], -1 /* HOISTED */)
            ]))
          ]),
          _createCommentVNode(" mobile & tablet menu "),
          _withDirectives(_createElementVNode("div", _hoisted_5, [
            _createVNode(_component_tree, {
              tree: $data.tree,
              onNewActiveTab: $options.handleActiveTab,
              onOnHoverTab: $options.changeHoverText,
              class: "bg-white border-gray-200"
            }, null, 8 /* PROPS */, ["tree", "onNewActiveTab", "onOnHoverTab"])
          ], 512 /* NEED_PATCH */), [
            [_vShow, $data.showMenu]
          ]),
          _createCommentVNode(" Desktop tree items "),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_tree, {
                tree: $data.tree,
                onNewActiveTab: $options.handleActiveTab,
                onOnHoverTab: $options.changeHoverText
              }, null, 8 /* PROPS */, ["tree", "onNewActiveTab", "onOnHoverTab"])
            ])
          ])
        ], 6 /* CLASS, STYLE */),
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "w-px h-64 bg-black cursor-ew-resize hidden lg:block" }, null, -1 /* HOISTED */)),
        _createVNode(_component_tab_manager, {
          ref: "tabManager",
          class: "bg-gray-200 border-2 border-gray-400 grow relative min-h-[50vh]",
          onNext: _cache[1] || (_cache[1] = $event => ($options.handleNext($event))),
          onBack: _cache[2] || (_cache[2] = $event => ($options.handleBack($event))),
          onNewActiveTab: $options.handleActiveTab
        }, null, 8 /* PROPS */, ["onNewActiveTab"])
      ]),
      _createCommentVNode("Information project"),
      _createElementVNode("div", _hoisted_8, [
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "flex justify-between text-sm px-2 py-1 gap-1" }, [
          _createElementVNode("div", { class: "flex-grow border border-gray-400 p-1" }, "3 proyectos"),
          _createElementVNode("div", { class: "border border-gray-400 w-32 p-1" }, "32kb"),
          _createElementVNode("div", { class: "flex-grow border border-gray-400 p-1" }, "0.3s")
        ], -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_9, _toDisplayString($data.hoverText), 1 /* TEXT */)
      ])
    ])
  ]))
}