import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader.js'
import { AsciiEffect } from 'three/examples/jsm/effects/AsciiEffect.js';

export function start3DModel() {
    //Create a clock for rotation
    const clock = new THREE.Clock()

    // Set rotate boolean variable
    let rotateModel = true

    let controls: OrbitControls

    // Creates empty mesh container
    const myMesh = new THREE.Mesh();

    // Scene
    const scene = new THREE.Scene()
    // scene.background = new THREE.Color( 0xff0000, 0);

    //Lights
    const pointLight1 = new THREE.PointLight(0xffffff, 1, 0, 0);
    pointLight1.position.set(100, 100, 400);
    scene.add(pointLight1);

    const pointLight2 = new THREE.PointLight(0xffffff, .5);
    pointLight2.position.set(-500, 100, -400);
    scene.add(pointLight2);

    // Parameters
    const stlLoader = new STLLoader()

    //Material
    const material = new THREE.MeshStandardMaterial()
    material.flatShading = true
    material.side = THREE.DoubleSide;

    // add ambient light
    const ambientLight = new THREE.AmbientLight(0xffffff, 1);
    scene.add(ambientLight);

    const light = new THREE.HemisphereLight( 0xffffbb, 0x080820, 1 );

    scene.add(light);

    const width = 10;
    const height = 10;
    const intensity = 1;
    const rectLight = new THREE.RectAreaLight(0xffffff, intensity, width, height);
    rectLight.position.set(5, 5, 0);
    rectLight.lookAt(0, 0, 0);
    scene.add(rectLight)


    let container = document.querySelector("#wm-wave") as HTMLElement;
    let bb = container.getBoundingClientRect();

    // Sizes
    const sizes = {
        width: bb.width,
        height: bb.height,
    }

    // Camera
    const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 1000)

    // Renderer
    const renderer = new THREE.WebGLRenderer({alpha: true})
    // renderer.setSize(bb.width, bb.height);
    let isScrolling = false;

    let characters = ' .:-+*=%@#XWM'
     //Chars darkness scale
     //  `.-':_,^=;><+!rc*/z?sLTv)J7(|Fi{C}fI31tlu[neoZ5Yxjya]2ESwqkP6h9d4VpOGbUAKXHm8RD#$Bg0MNWQ%&@
    characters = " ,~MW@"
    const effectSize = { amount: 0.3 }
    let backgroundColor = 'white'
    let ASCIIColor = 'rgb(59 130 246)'
    let effect: AsciiEffect

    function createEffect() {
        effect = new AsciiEffect(renderer, characters, { invert: false, resolution: effectSize.amount });
        effect.setSize(sizes.width, sizes.height);
        effect.domElement.style.color = ASCIIColor;
        // effect.domElement.style.backgroundColor = backgroundColor;
    }

    createEffect()

    // document.getElementById("ascii").style.whiteSpace = "prewrap"

    // container.appendChild(renderer!.domElement)
    container.appendChild(effect!.domElement)

    stlLoader.load(
        '/models/wm.stl',
        function (geometry) {

            geometry.scale(10, 10, 10)

            const directionalLight = new THREE.DirectionalLight( 0xffffff, 0.5 );
scene.add( directionalLight );

            // const texture = new THREE.TextureLoader()
            // .load('/models/Wood051_1K-JPG/Wood051_1K-JPG_Color.jpg')

            // texture.wrapS = THREE.RepeatWrapping;
            // texture.wrapT = THREE.RepeatWrapping;
            // texture.repeat.set(10, 2);
            // const roughnessMap =  new THREE.TextureLoader()
            // .load('/models/Wood051_1K-JPG/Wood051_1K-JPG_Roughness.jpg')
            // const normalMap =  new THREE.TextureLoader()
            // .load('/models/Wood051_1K-JPG/Wood051_1K-JPG_NormalGL.jpg')

            // const material = new THREE.MeshStandardMaterial({
            //     map: texture,
            //     roughnessMap: roughnessMap,
            //     normalMap: normalMap,
            //     // metalness: 0.5
            // });

            // add a knot
            // const knot = new THREE.TorusKnotGeometry(10, 3, 100, 16);
            // const knotMaterial = new THREE.MeshStandardMaterial({   map: texture,
            //     roughnessMap: roughnessMap,
            //     normalMap: normalMap });
            // const knotMesh = new THREE.Mesh(knot, knotMaterial);
            // scene.add(knotMesh);



            let material = new THREE.MeshStandardMaterial({ color: 0x3b82f6 });
            // material.flatShading = true;
            material.emissive = new THREE.Color(0x000000);
            // material.wireframe = true;
            material.roughness = 1;
            material.metalness = 0;
            myMesh.material = material

            myMesh.geometry = geometry;

            var tempGeometry = new THREE.Mesh(geometry, material)
            myMesh.position.copy = (tempGeometry.position)

            geometry.computeVertexNormals();
            myMesh.geometry.center()
            myMesh.rotation.y = 30 * Math.PI / 180
            myMesh.rotation.x = -90 * Math.PI / 180

            myMesh.geometry.computeBoundingBox();

            var bbox = myMesh.geometry.boundingBox;

            if (bbox) {
                myMesh.position.y = ((bbox.max.z - bbox.min.z) / 5)

                camera.position.x = ((bbox.max.x * 2));
                camera.position.y = ((bbox.max.y));
                camera.position.z = ((bbox.max.z * 3));
            }

            scene.add(myMesh);

            controls = new OrbitControls(camera, effect.domElement)
            controls.enableDamping = true

            controls.addEventListener('change', ()=>{
                isScrolling = true;
            })


            function tick() {
                if (isScrolling){
                    // only rotate on z
                    rotateModel = false
                }
                if (rotateModel == true) {
                    const elapsedTime = clock.getElapsedTime()
                    myMesh.rotation.z = (elapsedTime) 
                    // myMesh.rotation.y = (elapsedTime)  / 3
                    // myMesh.rotation.x = (elapsedTime)  / 4

                    // if (elapsedTime < 0.5) {
                    //     const speed = 0.5;
                    //     myMesh.scale.set(elapsedTime /speed, elapsedTime /speed, elapsedTime /speed)
                    // }

                    controls.autoRotate = true;
                    render()
                    window.requestAnimationFrame(tick)
                } else {
                    render()
                    window.requestAnimationFrame(tick)
                }
            }

            function render() {
                // required if controls.enableDamping or controls.autoRotate are set to true
	            controls.update();
                // renderer.render(scene, camera);
                try{
                    effect.render(scene, camera);
                } catch(e){
                    console.log(e)
                }
                
            }

            tick()
        }
    )

    window.addEventListener('scroll', function (e) {
        isScrolling = true;
        myMesh.rotation.x = 1.5
        myMesh.rotation.y = 0
        myMesh.rotation.z = (window.scrollY / 100)
    });
    window.addEventListener('resize', onWindowResize);

    function onWindowResize() {
        // camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        let bb = container.getBoundingClientRect();
        // renderer.setSize(bb.width, bb.height);
        effect.setSize(bb.width, bb.height);
    }
}