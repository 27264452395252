
export function flip(elm: HTMLElement, transform: Function, easing: string, duration: number = 300){
	// --------------
	// First: get the current bounds
	// ------------
	const first = elm.getBoundingClientRect();

	// execute the script that causes layout change
	transform(elm);

	// -----------------
	// Last: get the final bounds
	// -----------------
	const last = elm.getBoundingClientRect();

	// -----------------
	// Invert: 
	// -----------------
	// determine the delta between the first and last bounds to invert the element
	const deltaScaleWidth = first.width / last.width;
	const deltaScaleHeight = first.height / last.height;

	const deltaX = first.left - last.left;
	const deltaY = first.top - last.top; 

	// -------------
	// Play: 
	// -------------
	// animate the final element from its first bounds
	// to its last bounds (which is no transform)
	elm.animate([
		{ transform: `translate(${deltaX}px, ${deltaY}px) scale(${deltaScaleWidth}, ${deltaScaleHeight})` },
		{ transform: `translate(0, 0)` }
	], {
		duration: duration,
		easing: easing,
		fill: "forwards",
	});
}


// Convert a custom easing to linear()
// Source: https://developer.mozilla.org/en-US/blog/custom-easing-in-css-with-linear/#recreating_popular_eases_with_javascript
export const toLinear = (easing: Function, points = 50) => {
	const result = [...new Array(points + 1)]
		.map((d, i) => easing(i * (1 / points)));
	return `linear(${result.join(",")})`;
};

// EASING FUNCTIONS
export function easeOutElastic(x) {
	const c4 = (2 * Math.PI) / 3;

	const spring = 20;

	return x === 0
		? 0
		: x === 1
			? 1
			: Math.pow(2, -spring * x) * Math.sin((x * 10 - 0.75) * c4) + 1;

}

// Add intersection observer to animate on scroll
const observer = new IntersectionObserver((entries) => {
	entries.forEach(entry => {
		if (entry.isIntersecting) {
			let el = entry.target as HTMLElement;
			if (callbacks.has(el.dataset.observableId)) {
				callbacks.get(el.dataset.observableId)();
			}
		}
	});
}, {
	threshold: 0.5
});

var callbacks = new Map();

export function onReveal(el: HTMLElement, callback: (element: Element)=>void){
		el.dataset.observableId = Math.random().toString();
		callbacks.set(el.dataset.observableId, callback);
		observer.observe(el);
}
