import { createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "flex mt-2 relative" }
const _hoisted_2 = {
  key: 0,
  class: "mx-2 relative z-10"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  class: "border-r border-gray-300 border-dotted absolute top-5 bottom-0 left-3 w-px"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_tree_item = _resolveComponent("tree-item", true)

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" Active folder bg "),
    _createElementVNode("div", {
      class: _normalizeClass(["absolute inset-x-0 h-8 z-10 -mt-1.5 mr-4 rounded", $props.tree.active ? 'bg-blue-600' : ''])
    }, null, 2 /* CLASS */),
    ($props.tree.children && $props.tree.children.length !== 0 && $props.tree.type !== 'font' && $props.tree.type !== 'logo')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createCommentVNode(" Expand collapse button "),
          _createElementVNode("button", {
            class: "w-3 h-3 flex items-center justify-center py-2 bg-white",
            onClick: _cache[0] || (_cache[0] = $event => ($data.expanded = !$data.expanded))
          }, [
            _createElementVNode("img", {
              src: $data.expanded ? '/img/icon-less.png' : '/img/icon-plus.png'
            }, null, 8 /* PROPS */, _hoisted_3)
          ])
        ]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createCommentVNode(" Separator for leaf nodes "),
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "border-t border-gray-300 w-4 border-dotted mt-3 ml-3 mr-1" }, null, -1 /* HOISTED */))
        ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */)),
    _createElementVNode("button", {
      class: "relative w-full cursor-pointer",
      onClick: _cache[1] || (_cache[1] = _withModifiers((...args) => ($options.emitActive && $options.emitActive(...args)), ["stop"])),
      onMouseover: _cache[2] || (_cache[2] = _withModifiers((...args) => ($options.onHover && $options.onHover(...args)), ["stop"]))
    }, [
      _createCommentVNode(" vertical line"),
      ($props.tree.type == 'folder')
        ? (_openBlock(), _createElementBlock("div", _hoisted_4))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", {
        class: _normalizeClass(["flex items-center hover:bg-blue-400 relative z-10", $props.tree.active ? 'text-white text-sm' : 'text-gray-500 text-xs'])
      }, [
        (($props.tree.type == 'project' || $props.tree.type=='icon' || $props.tree.type==='logo' || $props.tree.type==='font') && ($props.tree.children && $props.tree.children.length > 0) || $props.tree.type == 'project')
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: $props.tree.logo,
              class: "size-6 object-contain mr-2 cursor-pointer"
            }, null, 8 /* PROPS */, _hoisted_5))
          : _createCommentVNode("v-if", true),
        ($props.tree.type == 'folder')
          ? (_openBlock(), _createElementBlock("img", {
              key: 1,
              src: $data.expanded ? '/img/icon-folder.png' : '/img/icon-folder-close.png',
              alt: "folder",
              class: "mr-2 w-5 h-5 cursor-pointer"
            }, null, 8 /* PROPS */, _hoisted_6))
          : _createCommentVNode("v-if", true),
        _createTextVNode(" " + _toDisplayString($props.tree.name) + " ", 1 /* TEXT */),
        ($props.tree.type == 'folder')
          ? (_openBlock(), _createElementBlock("span", {
              key: 2,
              class: _normalizeClass($props.tree.active ? 'text-gray-200' : 'text-gray-500')
            }, "   — (" + _toDisplayString($options.childrenCount) + ") ", 3 /* TEXT, CLASS */))
          : _createCommentVNode("v-if", true)
      ], 2 /* CLASS */),
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tree.children, (treeItem) => {
          return _withDirectives((_openBlock(), _createElementBlock("li", _hoisted_7, [
            _createVNode(_component_tree_item, {
              onNewActiveTab: $options.handleNewActiveTab,
              tree: treeItem
            }, null, 8 /* PROPS */, ["onNewActiveTab", "tree"])
          ], 512 /* NEED_PATCH */)), [
            [_vShow, $data.expanded && $props.tree.type != 'logo' &&  $props.tree.type != 'font']
          ])
        }), 256 /* UNKEYED_FRAGMENT */))
      ])
    ], 32 /* NEED_HYDRATION */)
  ]))
}