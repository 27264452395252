<template>

<div class="flex mt-2 relative">
    <!-- Active folder bg -->
    <div class="absolute inset-x-0 h-8 z-10 -mt-1.5 mr-4 rounded" :class="tree.active ? 'bg-blue-600' : ''"></div>

    <div class="mx-2 relative z-10" 
     v-if="tree.children && tree.children.length !== 0 && tree.type !== 'font' && tree.type !== 'logo'">
        <!-- Expand collapse button -->
        <button  class="w-3 h-3 flex items-center justify-center py-2 bg-white" @click="expanded = !expanded">
            <img :src="expanded ? '/img/icon-less.png' : '/img/icon-plus.png'">
        </button>
    </div>
    <!-- Separator for leaf nodes -->
    <div v-else class="border-t border-gray-300 w-4 border-dotted mt-3 ml-3 mr-1"></div>
    
    <button class="relative w-full cursor-pointer" @click.stop="emitActive" @mouseover.stop="onHover">

        <!-- vertical line-->
        <div class="border-r border-gray-300 border-dotted absolute top-5 bottom-0 left-3 w-px" v-if="tree.type == 'folder'"></div>

        <div class="flex items-center hover:bg-blue-400 relative z-10" :class="tree.active ? 'text-white text-sm' : 'text-gray-500 text-xs'">
            <img v-if="(tree.type == 'project' || tree.type=='icon' || tree.type==='logo' || tree.type==='font') && (tree.children && tree.children.length > 0) || tree.type == 'project'" :src="tree.logo"  class="size-6 object-contain mr-2 cursor-pointer">
            <img v-if="tree.type == 'folder'" :src="expanded ? '/img/icon-folder.png' : '/img/icon-folder-close.png'" alt="folder" class="mr-2 w-5 h-5 cursor-pointer">
            {{ tree.name }} 
            <span v-if="tree.type == 'folder'" :class="tree.active ? 'text-gray-200' : 'text-gray-500'">
              &nbsp; — ({{ childrenCount }})
            </span>
        </div>        
        <ul>
            <li class="" v-for="treeItem in tree.children" v-show="expanded && tree.type != 'logo' &&  tree.type != 'font'">
                <tree-item v-on:new-active-tab="handleNewActiveTab" :tree="treeItem"></tree-item>
            </li>
        </ul>
    </button>
    </div>

</template>

<script>
export default {
    props: {
        tree: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            expanded: false, 
        }
    },
    computed: {
        childrenCount(){
          return this.countChildren(this.tree)
        }
    },
    mounted(){
        // get query params
        if (window.location.hash == '#'+this.tree.id){
            this.expanded = true;
            this.$emit('newActiveTab', this.tree)
        } else {
            this.expanded = Math.random() > 0.5;
        }

    },
    methods: {
        emitActive(){
            this.$emit("newActiveTab", this.tree)
        },
        countChildren(treeItem){
            let count = 0;
            if (treeItem.type == 'project' || treeItem.type=='icon' || treeItem.type=='logo' || treeItem.type=='font'){
                return 1;
            }
            if (treeItem.children == null){ 
                return 1;
            }
            treeItem.children.forEach((child)=>{
                count += this.countChildren(child)
            })
            return count
        },
        onHover(){
            this.$emit("onHoverTab", this.tree)
        },
        handleNewActiveTab(eventData){
            this.$emit('newActiveTab', eventData)
        }
       
    }
};
</script>
