function drawImage(ctx: CanvasRenderingContext2D, img: HTMLImageElement, x: number, y: number, scale = 1) {
    const width = img.width * scale;
    const height = img.height * scale;

    ctx.drawImage(img, x, y, width, height);
}

class Player {
    public dy = 0;  
    private ddy = 1200;  
    private jumpStrength = -350; 
    public canvasHeight: number;
    public width = 50; 
    public height = 50;
    private frame = 0;

    public isJumping = false;  

    constructor(public x: number, public y: number, public playerImg: HTMLImageElement[], private groundHeight: number) {}

    update(deltaTime: number) {
        if (
            (keys.includes('Space') || keys.includes('KeyW') || keys.includes('ArrowUp')) 
            && !this.isJumping
        ) {
            this.jump();
        }

        this.dy += this.ddy * deltaTime;
        this.y += this.dy * deltaTime;

        if (this.y > this.canvasHeight - this.groundHeight - this.height) {
            this.y = this.canvasHeight - this.groundHeight - this.height;
            this.dy = 0;
            this.isJumping = false;
        }
    }

    render(ctx: CanvasRenderingContext2D) {
        this.frame++;
        if (this.isJumping) {
            ctx.drawImage(this.playerImg[0], this.x, this.y + 10, this.width, this.height);
            return;
        }
        ctx.drawImage(this.playerImg[Math.floor(this.frame / 20) % 2], this.x, this.y + 10, this.width, this.height);
    }

    jump() {
        this.dy = this.jumpStrength;
        this.isJumping = true;
    }
}

class Cloud {
    x: number;
    y: number;
    width: number;
    height: number;
    speed: number = 200;

    constructor(canvasWidth: number, canvasHeight: number, public cloudImg: HTMLImageElement) {
        this.width = this.height = Math.random() * 60 + 30; 
        this.x = canvasWidth + Math.random() * 100; 
        this.y = Math.random() * (canvasHeight / 3);
        this.speed = this.width;
    }
    
    update(deltaTime: number) {
        this.x -= this.speed * deltaTime; 
    }

    render(ctx: CanvasRenderingContext2D) {
        ctx.drawImage(this.cloudImg, this.x, this.y, this.width, this.height);

    }
}


class Obstacle {
    public x: number;
    public y: number;
    public width: number;
    public height: number;

    constructor(x: number, groundHeight: number) {
        this.x = x;
        this.width = 20; 
        this.height = 20; 
        this.y = groundHeight - this.height;
    }

    update(deltaTime: number) {
        this.x -= 200 * deltaTime; 
    }

    render(ctx: CanvasRenderingContext2D) {
        ctx.fillStyle = '#FFFDF1'; 
        ctx.beginPath();
        ctx.moveTo(this.x, this.y + this.height);  
        ctx.lineTo(this.x + this.width / 2, this.y); 
        ctx.lineTo(this.x + this.width, this.y + this.height);  
        ctx.closePath();
        ctx.fill();
    }
}


function colliding(player: Player, obstacle: Obstacle): boolean {
    const playerRight = player.x + player.width;  
    const playerLeft = player.x;
    const playerBottom = player.y + player.height;  
    const playerTop = player.y;

    const x1 = obstacle.x + 20;
    const y1 = obstacle.y + obstacle.height;  
    const x2 = obstacle.x + obstacle.width / 2;
    const y2 = obstacle.y;  
    const x3 = obstacle.x - 20 + obstacle.width;
    const y3 = obstacle.y + obstacle.height; 

    const collidingX = (
        (playerRight > x1 && playerLeft < x3) 
    );

    const collidingY = (
        (playerBottom > y2 && playerTop < y1) 
    );

    return collidingX && collidingY;
}



export class FooterGame {
    canvas: HTMLCanvasElement;
    ctx: CanvasRenderingContext2D;
    width: number;
    height: number;
    frameRate = 60;
    player: Player;
    minGap = 100;
    private startGameHandler: (event: Event) => void;

    obstacles: Obstacle[] = [];
    clouds: Cloud[] = [];
    fps = 0;
    lastFrameTime = 1;
    startTime = performance.now();
    gameOver = false;
    hasStarted = false;

    fpsEl = document.querySelector('#fps');
    fpsCanvas: HTMLCanvasElement;
    fpsCtx: CanvasRenderingContext2D;

    playerImg: HTMLImageElement[];
    bg: HTMLImageElement;
    spike: HTMLImageElement;
    cloudImg: HTMLImageElement;

    groundHeight: number = 20;
    lastSpikeTime = 0; 
    minSpikeInterval = 1;
    scoreInterval: number | null = null;

    constructor() {
        this.canvas = document.getElementById('myCanvas') as HTMLCanvasElement;
        if (!this.canvas) {
            throw new Error('Canvas not found');
        }

        this.canvas.width = window.innerWidth;  
        this.canvas.height = window.innerHeight / 4;  
        this.ctx = this.canvas.getContext('2d', { alpha: false }) as CanvasRenderingContext2D;

        this.fpsCanvas = document.querySelector('#fpsCanvas') as HTMLCanvasElement;
        this.fpsCtx = this.fpsCanvas.getContext('2d') as CanvasRenderingContext2D;

        this.width = this.canvas.width;
        this.height = this.canvas.height;

        
        let playerFrame1 = new Image();
        let playerFrame2 = new Image();
        playerFrame1.src = '/img/player.png'; 
        playerFrame2.src = '/img/player-2.png';
        this.playerImg = [playerFrame1, playerFrame2];

        this.spike = new Image();
        this.spike.src = '/img/spike.png';  

        this.cloudImg = new Image();
        this.cloudImg.src = '/img/cloud.png';

        playerFrame1.onload = () => {
            this.drawStartScreen();  
        };
        

        this.ctx.fillStyle = 'white';
        let fontSize = 32;
        fontSize = Math.min(fontSize, this.width / 10);
        this.ctx.font = fontSize + "px 'Press Start 2P'";

        this.startGameHandler = (event: Event) => {
            this.startGame();
        };
      
        this.canvas.addEventListener("click", this.startGameHandler, { once: true });
      
        window.addEventListener('resize', () => {
            this.canvas.width = window.innerWidth;
            this.canvas.height = window.innerHeight / 4;
            this.width = this.canvas.width;
            this.height = this.canvas.height;
            if (!this.hasStarted) {
              this.drawStartScreen();
              this.canvas.removeEventListener("click", this.startGameHandler);
              this.canvas.addEventListener("click", this.startGameHandler, { once: true });
            }
        });

    }

    drawStartScreen() {
        this.ctx.fillStyle = '#221F20'; 
        this.ctx.fillRect(0, 0, this.width, this.height);
        this.ctx.fillStyle = 'white';
    
        if (window.innerWidth < 768) {
            const lines = ["Toca", "para", "comenzar"];
            const lineHeight = 40; 
            const totalTextHeight = lineHeight * lines.length;
            let startY = (this.height - totalTextHeight) / 2 + lineHeight * 0.8;
            
            lines.forEach((line, index) => {
                const fontWidth = this.ctx.measureText(line).width;
                const x = (this.width - fontWidth) / 2;
                const y = startY + index * lineHeight;
                this.ctx.fillText(line, x, y);
            });
        } else {
            const text = "Toca para comenzar";
            const fontWidth = this.ctx.measureText(text).width;
            this.ctx.fillText(text, (this.width - fontWidth) / 2, this.height / 2);
        }
    }
    
    
    

    startGame() {
        const instructions = document.getElementById("gameInstructions");
        const scoreElement = document.getElementById("score");
        const scoreBoard = document.getElementById("scoreBoard");
    
        if (instructions) {
            instructions.classList.remove("hidden");
            instructions.classList.add("block");
        }
    
        if (scoreElement) {
            scoreElement.classList.remove("hidden");
            scoreElement.classList.add("block");
        }

        if (scoreBoard) {
            scoreBoard.classList.remove("hidden");
            scoreBoard.classList.add("block");
        }

        let now = performance.now();
        this.lastFrameTime = now;
        this.startTime = performance.now();
        this.player = new Player(this.width / 3, this.height - this.groundHeight - 40, this.playerImg, this.height - this.groundHeight);

        this.clouds = [];
        for (let i = 0; i < 1; i++) {
            this.clouds.push(new Cloud(this.width, this.height, this.cloudImg));
        }

        this.obstacles = [];
        for (let i = 0; i < 500; i++) {
            let xCord = this.width + i * 300;
            this.obstacles.push(new Obstacle(xCord, this.height - this.groundHeight));
        }
    
        this.gameOver = false;
        requestAnimationFrame(this.gameLoop.bind(this));

        let score = 0;
        document.getElementById('score')!.innerText = `${score}`;
        const scoreIncreaseRate = 1; 
        this.scoreInterval = setInterval(() => {
            score += scoreIncreaseRate;
            score = Math.max(0, score); 
            document.getElementById('score')!.innerText = `${Math.round(score)}`;
        }, 100);
        this.hasStarted = true;

        if (this.hasStarted) {
            addKeyListeners();
        }
        
    }

    update(deltaTime: number) {
        this.player.update(deltaTime);

        if (this.player.y > this.height - this.groundHeight - this.player.height) {
            this.player.y = this.height - this.groundHeight - this.player.height;
            this.player.dy = 0;
            this.player.isJumping = false;
        }

        let currentTime = performance.now() / 1200; 
        if (currentTime - this.lastSpikeTime > this.minSpikeInterval) {
            let xCord = this.width + 200;
            this.obstacles.push(new Obstacle(xCord, this.height - this.groundHeight));
            this.lastSpikeTime = currentTime; 
        }

        this.obstacles.forEach(obstacle => {
            obstacle.update(deltaTime);
            if (colliding(this.player, obstacle)) {
                this.gameOver = true;
                return;
            }
        });

        if (Math.random() < 0.01) {
            this.clouds.push(new Cloud(this.width, this.height, this.cloudImg));
        }

        this.clouds.forEach(cloud => cloud.update(deltaTime));
    }

    gameLoop() {
        if (this.gameOver) {
            this.gameOverScreen();
            return;
        }
    
        let now = performance.now();
        let dt = (now - this.lastFrameTime) / 1000;
        this.lastFrameTime = now;
    
        if (this.fpsCtx !== null) {
            this.fps = 1 / dt;
            let data = this.fpsCtx.getImageData(0, 0, this.fpsCanvas.width, this.fpsCanvas.height);
            this.fpsCtx.clearRect(0, 0, this.fpsCanvas.width, this.fpsCanvas.height);
            this.fpsCtx.putImageData(data, -1, 0);
            this.fpsCtx.fillStyle = 'rgb(147 197 253)';
            this.fpsCtx.fillRect(this.fpsCanvas.width - 1, this.fpsCanvas.height - this.fps, 1, this.fps);
            this.fpsEl!.innerHTML = `FPS: ${Math.round(this.fps)}`;
        }
    
        this.ctx.fillStyle = '#221F20'; 
        this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);  
        this.update(dt);
    
        this.clouds.forEach(cloud => cloud.render(this.ctx));
        this.player.render(this.ctx);
        this.obstacles.forEach(obstacle => obstacle.render(this.ctx));
    
        this.ctx.fillStyle = '#221F20'; 
        this.ctx.fillRect(0, this.height - this.groundHeight, this.width, this.groundHeight); 
    
        this.ctx.strokeStyle = '#FFFDF1';  
        this.ctx.lineWidth = 3;  
        this.ctx.beginPath();
        this.ctx.moveTo(0, this.height - this.groundHeight); 
        this.ctx.lineTo(this.width, this.height - this.groundHeight);  
        this.ctx.stroke();  

        requestAnimationFrame(this.gameLoop.bind(this));
    }
    
    

    gameOverScreen() {

        let fontSize = 32;
        fontSize = Math.min(fontSize, this.width / 10);
        this.ctx.fillStyle = 'white';
        this.ctx.font = fontSize + "px 'Press Start 2P'";

        if (this.scoreInterval !== null) {
            clearInterval(this.scoreInterval); 
        }

        let button = document.querySelector('#restartButton') as HTMLButtonElement;
        button.style.display = 'block';
        button.onclick = () => {
            this.startGame();
            button.style.display = 'none';
        };

        this.hasStarted = false;
    }
}

let keys: String[] = [];

function addKeyListeners() {
        
    document.addEventListener('mousedown', (event) => {
        keys.push('Space')
    })

    document.addEventListener('mouseup', (event) => {
        keys = keys.filter(key => key !== 'Space')
    })

    document.addEventListener('touchstart', (event) => {
        keys.push('Space')
    })

    document.addEventListener('touchend', (event) => {
        keys = keys.filter(key => key !== 'Space')
    })


    document.addEventListener('keydown', (event) => {
        // ignore if is an input field
        const element = event.target as HTMLElement;
        if (element.tagName === 'INPUT' || element.tagName === 'TEXTAREA') {
            return;
        }

        if (event.code === 'Space' || event.code === 'KeyW' || event.code === 'ArrowUp') {
            if (!keys.includes(event.code)) {
                keys.push(event.code);
            }
            event.preventDefault();
        }
    });

    document.addEventListener('keyup', (event) => {
        if (event.code === 'Space' || event.code === 'KeyW' || event.code === 'ArrowUp') {
            keys = keys.filter(key => key !== event.code);
        }
    });
}