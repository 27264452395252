<template>
  <div class="p-4">
    <div class="flex items-center gap-2">
    <img 
        :src="tree.logo ? tree.logo : '/img/icon-folder.png'" 
        alt="icon" 
        :class="tree.logo ? 'w-6 h-6' : ''" 
        class="mr-2"
      >
      {{ tree.name }}
    </div>
       <!-- vertical line-->
       <div class="border-r border-gray-300 border-dotted absolute top-10 bottom-4 left-7 w-px"></div>

    <div v-for="treeItem in tree.children" :key="treeItem.id" class="ml-5">
      <div class="flex items-center mt-2">
      <img 
        :src="treeItem.logo ? treeItem.logo : '/img/icon-folder.png'" 
        alt="icon" 
        :class="treeItem.logo ? 'w-6 h-6' : ''" 
        class="mr-2"
      >
      {{ treeItem.name }}
    </div>

      <!-- vertical line-->
      <div class="border-r border-gray-300 border-dotted absolute top-10 bottom-4 left-12 w-px"></div>

      <tree-item 
        v-for="child in treeItem.children" 
        :key="child.id"
        :tree="child"
        @new-active-tab="$emit('newActiveTab', $event)">
      </tree-item>
    </div>
  </div>
</template>

<script>
export default {
  methods: { },
  props: {
    tree: {
      type: Array,
      required: true,
    },
  },
  data() {}
};
</script>