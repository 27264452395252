<template>
    <div>
        <div class="flex gap-2 p-2 overflow-x-auto">
            <tab :data="tab" v-for="tab in tabs" :key="tab.id" @close-tab="removeTab" @open-tab="openTab" @next="handleNext($event)" @back="handleBack($event)" @new-active-tab="$emit('newActiveTab', $event)"></tab>
        </div>
    </div>
    </template>
    <script>
    export default {
        data(){
            return {
                tabs: []
            }
        },
        methods: {
            newTab(newTab){
                this.tabs.forEach((tab)=>{
                    tab.active = false; 
                })
                let exists = false;
                this.tabs.forEach((tab)=>{
                   if (newTab.id == tab.id){
                    exists = true;
                    tab.active = true;
                   }
                })

                if (exists == false){
                    newTab.active = true;

                    // if there are more than 5 tabs, remove the first
                    if (this.tabs.length >= 5){
                        this.tabs.shift();
                    }
    
                    // if the tab already exists, switch to the tab
                    this.tabs.push(newTab)
                }
            },
            removeTab(id) {
                let active = false;
                // get status of tab to be removed
                for (var i = 0; i < this.tabs.length; i++){
                    if (this.tabs[i].id == id){
                        active = this.tabs[i].active
                    }
                }
                // remove tab from array
                this.tabs = this.tabs.filter(tab => tab.id !== id);
                
                // set another tab as active
                if (active && this.tabs.length > 0){
                    this.tabs[this.tabs.length-1].active = true;
                }
            },
            openTab(id){
                this.tabs.forEach((tab)=>{
                    tab.active = false; 
                })
                // get status of tab to be removed
                for (var i = 0; i < this.tabs.length; i++){
                    if (this.tabs[i].id == id){
                      this.tabs[i].active = true
                      this.$emit('newActiveTab', this.tabs[i])
                    }
                }
            },
            handleNext(treeItem){
                this.$emit('next', treeItem)
               
            },
            handleBack(treeItem){
                this.$emit('back', treeItem)
               
            }
        }
    }
    </script>