// require("./tailwind.css")

import { setup } from './wm-intro.ts';
import { home } from "./home";

import { easeOutElastic, onReveal, toLinear } from "./utils"
import Alpine from 'alpinejs'

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

import { initVue } from './vue.ts';
import { FooterGame } from './game.ts';
import { aboutUsPage } from './about-us.ts';
import { calculator } from './calculator.ts';


declare global {
    interface Window {
        vm: any, // Vue.js instance
        observer: any
    }
}


setupTransition()

document.addEventListener('DOMContentLoaded', () => {
	if (window.location.pathname !== "/_preview/" && window.location.pathname !== "/") {
		console.log(window.location.pathname)
		initVue();
	}

	animateHeading()

  window.setTimeout(() => {
    setup();

  }, 200)

  // Fancybox (showcase pictures)
  Fancybox.bind("[data-fancybox]", {
    // Your custom options
  });

})
declare global {
	interface Window {
		Alpine: typeof Alpine;
	}
}

import Lenis from 'lenis'
import { setupTransition } from './fancy-transition.ts';

// Initialize Lenis
new Lenis({
	autoRaf: true,
	duration: 0.5,
});
//window.flip = flip;

function service() {
	const stickers = document.querySelectorAll(".sticker");
	const container = document.querySelector("#services");
	if (!container) return;

	const containerRect = container.getBoundingClientRect();

	stickers.forEach((sticker) => {
		const stickerRect = sticker.getBoundingClientRect();
		const valueS = sticker as HTMLElement;

		const spreadX = 0.4;
		const spreadY = 0.4;
		const centerX = ((containerRect.width / 2) - (stickerRect.width / 2) - (stickerRect.left - containerRect.left)) * spreadX;
		const centerY = ((containerRect.height / 2) - (stickerRect.height / 2) - (stickerRect.top - containerRect.top)) * spreadY;

		const randomRotation = Math.floor(Math.random() * 24) - 12;

		onReveal(valueS, (entry) => {
			sticker.animate(
				[
					{ transform: `translate(${centerX}px, ${centerY}px) scale(1.05) rotate(${randomRotation}deg)` }
				],
				{
					duration: 1000,
					easing: toLinear(easeOutElastic, 32),
					iterations: 1,
					fill: "forwards"
				}
			);
		
		});

		
	});

	const techLogos = document.querySelectorAll(".tech-logo");

	techLogos.forEach((logo) => {
		let logoEl = logo as HTMLElement;

		logoEl.addEventListener("mouseover", function () {
			logoEl.style.zIndex = "100";
		});

		logoEl.addEventListener("mouseenter", function () {
			logoEl.animate(
				[
					{ transform: "scale(1.50) translateY(-40px)" } 
				],
				{
					duration: 400,
					easing: "ease-in-out",
					fill: "forwards"
				}
			);
		});

		logoEl.addEventListener("mouseleave", function () {
			logoEl.style.zIndex = "0";
			logoEl.animate(
				[
					{ transform: "scale(1) translateY(0)" } 
				],
				{
					duration: 400,
					easing: "ease-in-out",
					iterations: 1,
					fill: "forwards"
				}
			);
		});

	})

}

let game: FooterGame;
document.addEventListener("DOMContentLoaded", function () {
	service()
	setTimeout(()=>{
		game = new FooterGame();
	}, 1000)

	const techLogos = document.querySelectorAll(".tech-logo");

	techLogos.forEach((logo) => {
		const logoEl = logo as HTMLElement;
		logoEl.style.transform = "scale(0)";

		onReveal(logo as HTMLElement, (entry) => {
			logo.animate([
				{ transform: "scale(1)" }
			], {
				delay: 300 + Math.random() * 300,
				duration: 400,
				easing: toLinear(easeOutElastic, 32),
				iterations: 1,
				fill: "forwards"
			})
		})
	});

	aboutUsPage();
	
	homePageAnimations();

	Alpine.start()

	setTimeout(() => {
		home()
	}, 500)

	try {
		calculator();
	} catch(e){
		console.log("error loading calculator")
	}
});

function homePageAnimations(){
	let mouseDown = false;
	let ventaniaEl = document.querySelector("#ventaniaWindow") as HTMLElement;
	if (ventaniaEl == null) return;

	ventaniaEl.addEventListener("mousedown", ()=>{
		mouseDown = true;
	})

	ventaniaEl.addEventListener("mousemove", (e)=>{
	})

	document.addEventListener("mouseup", ()=>{
		mouseDown = false;
	})
}

async function animateHeading(){
	let youDream = document.querySelector("#you-dream") as HTMLElement;
	if (youDream) {
		// split every litter in you dream
		let text = youDream.innerText;
		let newText = "";
		for (let i = 0; i < text.length; i++) {
			newText += `<span>${text[i]}</span>`;
		}
		youDream.innerHTML = newText;
		// iterate over each of the new spans
		let spans = youDream.querySelectorAll("span");
		for (let i = 0; i < spans.length; i++) {
			// animate the span
			spans[i].animate([
				{ opacity: 0, transform: "translateY(100%)" },
				{ opacity: 1,transform: "translateY(0)" }
			], {
				duration: 100,
				delay: 100 * (0.4 * i),
				easing: "ease-in",
				iterations: 1,
				fill: "forwards"
			}).onfinish = () => {
				// add the class "font-gamer" to the span
			// spans[i].classList.add("font-gamer")
			// spans[i].style.fontSize = "4rem";
			};
		}

		// sleep for 200ms
		// await new Promise((resolve) => setTimeout(resolve, 200));
	}
}