function updateSummary(group, value) {
	document.getElementById("summary-" + group).textContent = value;
}

let sumTotal = 0;

let selectedValues = {
	contact: [],
	domain: null,
	hosting: null,
	modifications: null,
	maintenance: null,
	analytics: null,
	mail: null,
	photograph: null,
	brand: null,
	design: null,
	views: null,
	webPageLanguage: [],
};

const defaultSelections = {
	contact: 'Whatsapp',
	domain: 'Ya tengo',
	hosting: 'Ya tengo',
	modifications: 'No requiero',
	maintenance: 'No requiero',
	analytics: 'Semestral',
	mail: 'No necesito',
	photograph: 'Ya las tengo',
	brand: 'Ya tengo',
	design: 'Plantilla',
	views: '1',
	webPageLanguage: 'Español',
};

export function calculator() {
		const serviceRadios = document.querySelectorAll('input[name="serviceType"]');
		const serviceTypeSumm = document.getElementById('summary-serviceType');
		serviceRadios.forEach((radio) => {
			radio.addEventListener('change', function () {
				if (this.checked) {
					serviceTypeSumm.textContent = this.value;
				}
			});
		});

		const appServiceRadios = document.querySelectorAll('input[name="appServiceType"]');
		const appServiceTypeSumm = document.getElementById('summary-appServiceType');
		appServiceRadios.forEach((radio) => {
			radio.addEventListener('change', function () {
				if (this.checked) {
					appServiceTypeSumm.textContent = this.value;
				}
			});
		});

		const selectElement = document.getElementById('serviceSelect');
		const serviceButtons = document.querySelectorAll('.service-button');
		const formDivs = document.querySelectorAll('.serviceDiv');
		const submitter = document.querySelectorAll('.formResult');
		const summary = document.querySelectorAll('.formmSummary');

		serviceButtons.forEach(button => {
			button.addEventListener('click', function () {

				serviceButtons.forEach(btn => btn.classList.remove('bg-green-500'));

				button.classList.add('bg-green-500');


				formDivs.forEach(div => div.classList.add('hidden'));
				submitter.forEach(div => div.classList.add('hidden'));
				summary.forEach(div => div.classList.add('hidden'));

				const selectedService = button.getAttribute('data-service');

				if (selectedService == '1') {
					document.getElementById('webPage').classList.remove('hidden');
					document.getElementById('webPageSummary').classList.remove('hidden');
					document.getElementById('formResultWebPage').classList.remove('hidden');
				} else if (selectedService == '2') {
					document.getElementById('eCommerce').classList.remove('hidden');
					document.getElementById('ecommerceSummary').classList.remove('hidden');
					document.getElementById('formResultEcommerce').classList.remove('hidden');
				} else if (selectedService == '3') {
					document.getElementById('platform').classList.remove('hidden');
					document.getElementById('platformSummary').classList.remove('hidden');
					document.getElementById('formResultPlatform').classList.remove('hidden');
				} else if (selectedService == '4') {
					document.getElementById('apps').classList.remove('hidden');
					document.getElementById('appSummary').classList.remove('hidden');
					document.getElementById('formResultApp').classList.remove('hidden');
				}
			});
		});

		const totalCost = document.getElementById('totalCost');
		const contactButtons = document.querySelectorAll('.contact-button');
		handleMultiButtonClick(contactButtons, 'contact');

		const webPageLanguagesButtons = document.querySelectorAll('.webPageLanguage-button');
		handleMultiButtonClick(webPageLanguagesButtons, 'webPageLanguage');

		const domainButtons = document.querySelectorAll('.domain-button');
		handleButtonClick(domainButtons, 'domain');

		const hostingButtons = document.querySelectorAll('.hosting-button');
		handleButtonClick(hostingButtons, 'hosting');

		const modificationButtons = document.querySelectorAll('.modification-button');
		handleButtonClick(modificationButtons, 'modifications');

		const maintenanceButtons = document.querySelectorAll('.maintenance-button');
		handleButtonClick(maintenanceButtons, 'maintenance');

		const analyticsButtons = document.querySelectorAll('.analytics-button');
		handleButtonClick(analyticsButtons, 'analytics');

		const mailButtons = document.querySelectorAll('.mail-button');
		handleButtonClick(mailButtons, 'mail');

		const photographButtons = document.querySelectorAll('.photograph-button');
		handleButtonClick(photographButtons, 'photograph');

		const brandButtons = document.querySelectorAll('.brand-button');
		handleButtonClick(brandButtons, 'brand');

		const designButtons = document.querySelectorAll('.design-button');
		designButtons.forEach(button => {
			button.addEventListener('click', function () {
				const designValue = button.dataset.value;

				if (selectedValues['design'] !== designValue) {
					if (selectedValues['design'] !== null) {
						sumTotal -= selectedValues['design'] === 'Plantilla' ? 0 : 0;
					}

					selectedValues['design'] = designValue;
					updatePagesForDesign(designValue);
				}
			});
		});

		const pageButtons = document.querySelectorAll('.views-button');
		pageButtons.forEach(button => button.disabled = true);
		handleButtonClick(pageButtons, 'views');
		document.querySelectorAll('.option-button').forEach(button => {
			button.addEventListener('click', function () {
				const group = button.dataset.group;
				const value = button.dataset.value;

				const hiddenInput = document.getElementById(group);
				if (hiddenInput) {
					hiddenInput.value = value;
				}

				updateSummary(group, value)

				document.querySelectorAll(`.option-button[data-group="${group}"]`).forEach(btn => {
					btn.classList.remove('bg-green-500');
				});
				button.classList.add('bg-green-500');
			});
		});

		document.querySelectorAll('.multioption-button').forEach(button => {
			button.addEventListener('click', function () {
				const group = button.dataset.group;
				const input = document.getElementById(group);

				button.classList.toggle('bg-green-500');



				const selectedButtons = document.querySelectorAll(`.multioption-button[data-group="${group}"].bg-green-500`);

				if (input) {
					input.value = Array.from(selectedButtons).map(btn => btn.dataset.value).join(', ');
				}

				updateSummary(group, input.value)
			});
		});



		Object.keys(defaultSelections).forEach(group => {
			const defaultValue = defaultSelections[group];
			const button = document.querySelector(`.option-button[data-group="${group}"][data-value="${defaultValue}"]`) ||
				document.querySelector(`.multioption-button[data-group="${group}"][data-value="${defaultValue}"]`);

			if (button) {
				button.click();
			}
		});

		updatePagesForDesign(defaultSelections['design']);
}



function handleButtonClick(buttons, group) {
	buttons.forEach(button => {
		button.addEventListener('click', function () {
			const buttonValue = parseFloat(button.value);

			if (selectedValues[group] !== null) {
				sumTotal -= selectedValues[group];
			}

			sumTotal += buttonValue;
			selectedValues[group] = buttonValue;
			totalCost.textContent = sumTotal.toFixed(2);
			buttons.forEach(btn => btn.disabled = false);
			button.disabled = true;
		});
	});
}

function handleMultiButtonClick(buttons, group) {
	buttons.forEach(button => {
		button.addEventListener('click', function () {
			const buttonValue = parseFloat(button.value);

			if (selectedValues[group].includes(button)) {
				sumTotal -= buttonValue;
				selectedValues[group] = selectedValues[group].filter(btn => btn !== button);
				button.disabled = false;
			} else {
				selectedValues[group].push(button);
				sumTotal += buttonValue;
				button.disabled = false;
			}

			totalCost.textContent = sumTotal.toFixed(2);
		});
	});
}

function updatePagesForDesign(designValue) {
	const pageButtons = document.querySelectorAll('.views-button');

	if (designValue === 'Plantilla') {
		pageButtons[0].value = '100';
		pageButtons[1].value = '200';
		pageButtons[2].value = '300';
	} else if (designValue === 'A la medida') {
		pageButtons[0].value = '350';
		pageButtons[1].value = '950';
		pageButtons[2].value = '1500';
	}

	pageButtons.forEach(button => button.disabled = false);

}

