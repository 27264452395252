import { onReveal } from "./utils";

export function aboutUsPage() {

	setTimeout(()=>{
		let paintIframe = document.querySelector("#paint") as HTMLIFrameElement;
		if (paintIframe) {
			paintIframe.src = "/vendor/jspaint/index.html#load:https://beta.wemake.pe/img/resources/wm-logo-pile-rgb.png"
		}
	}, 1000)

	const moralValues = document.querySelectorAll(".moral-value");

	moralValues.forEach((value) => {
		const valueEl = value as HTMLElement;

		let initialRotate = "0deg";
		let initialTranslateX = "0px";
		let initialTranslateY = "0px";

		const src = value.getAttribute("src");
		if (src && src.includes("trabajo_en_equipo")) {
			initialRotate = "-15deg";
			initialTranslateX = "80px";

		} else if (src && src.includes("competitividad")) {
			initialRotate = "-10deg";
			initialTranslateX = "-120px";
			initialTranslateY = "-60px";

		} else if (src && src.includes("confianza")) {
			initialRotate = "15deg";
			initialTranslateX = "-190px";
			initialTranslateY = "20px";

		} else if (src && src.includes("originalidad")) {
			initialRotate = "10deg";

		} else if (src && src.includes("calidad")) {
			initialTranslateX = "140px";
			initialTranslateY = "20px";

		} else if (src && src.includes("responsabilidad")) {
			initialTranslateX = "170px";
			initialTranslateY = "-25px";
		}

		valueEl.style.transform = `rotate(${initialRotate}) translate(${initialTranslateX}, ${initialTranslateY})`;

		onReveal(valueEl, (entry: Element) => {
			value.animate([
				{ transform: `rotate(${initialRotate}) translate(${initialTranslateX}, ${initialTranslateY})` },
				{ transform: "rotate(0deg) translate(0px, 0px)" }
			], {
				delay: 300 + Math.random() * 100,
				duration: 1000,
				easing: "ease-in-out",
				iterations: 1,
				fill: "forwards"
			});
		});
	});

	const bgParallax = document.querySelector(".bg-parallax") as HTMLElement;

	const barSegments = document.querySelectorAll<HTMLDivElement>(".bar-segment");
	const totalSegments = barSegments.length;

	const handleScroll = () => {
		const scrollTop = window.scrollY;
		const documentHeight = document.documentElement.scrollHeight - window.innerHeight;
		const scrollPercentage = Math.min(scrollTop / documentHeight, 1);

		// parallax effect
		const translateY = scrollPercentage * 100;
		if (bgParallax) {
			bgParallax.style.backgroundPosition = `center ${translateY}%`;
		}


		// loading bar
		const segmentsToFill = Math.floor(scrollPercentage * totalSegments);

		barSegments.forEach((segment, index) => {
			if (index < segmentsToFill) {
				segment.style.backgroundColor = "#394DCD";  // blue
			} else {
				segment.style.backgroundColor = "#A7A8AA"; // gray
			}
		});
	};

	window.addEventListener("scroll", handleScroll);


}