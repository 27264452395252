<template>
    <section class="bg-[url(/img/bg-xp.webp)] bg-cover">
        <div class="max-w-screen-xl mx-auto pt-4 pb-4 px-2 shadow-lg">
            <!-- Top bar-->
            <div class="bg-[#394DCD] px-2 flex justify-between items-center">
                <div class="flex items-center">
                    <img src="/img/icon-folder-2.png" alt="">
                    <p class="p-2 text-white">C:\WeMakeeeee Portafolio</p>
                </div>
                <div class="flex space-x-1">
                    <button class="w-5 h-6 bg-gray-300 border text-black"> <!--Minimize SVG-->
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" class="size-4">
                            <path d="M22 20H2c-0.55 0-1-0.45-1-1s0.45-1 1-1h20c0.55 0 1 0.45 1 1s-0.45 1-1 1z" />
                        </svg>
                    </button>
                    <button class="w-6 h-6 bg-gray-300 border text-black flex items-center justify-center">
                        <!--Maximize SVG-->
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" class="w-4 h-4">
                            <path
                                d="M22 2H2C0.9 2 0 2.9 0 4v16c0 1.1 0.9 2 2 2h20c1.1 0 2-0.9 2-2V4c0-1.1-0.9-2-2-2zm0 18c0 0.55-0.45 1-1 1H3c-0.55 0-1-0.45-1-1V9h20v11z" />
                        </svg>
                    </button>
                    <button class="w-4 h-6 bg-gray-300 border text-black flex items-center justify-center">
                        <!--Close SVG-->
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="black" fill="black"
                            stroke-width="1.5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
            </div>

            <!--Tree side-->
            <div class="flex flex-col lg:flex-row">
                <div :style="'width: ' + width + '%'" class="relative" :class="isMobile ? '' : ' shrink-0'">
                    <div class="bg-gray-300 border border-gray-950 px-4 py-2.5 flex items-center justify-between">
                        Nuestros proyectos  
                    <button class="lg:hidden" @click="showMenu = !showMenu">  
                            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                <rect y="8" width="24" height="2" fill="black"/>
                                <rect y="17" width="24" height="2" fill="black"/>
                                <rect y="26" width="24" height="2" fill="black"/>
                            </svg>
                    </button>
                </div>
                <!-- mobile & tablet menu -->
                <div class="overflow-y-auto max-h-[80vh] absolute left-0 top-13 h-[36vh] bg-white border shadow z-20 w-full" 
                    v-show="showMenu">
                    <tree :tree="tree" v-on:new-active-tab="handleActiveTab" v-on:on-hover-tab="changeHoverText"
                        class="bg-white border-gray-200"></tree>
                </div>

                <!-- Desktop tree items -->
                <div class="lg:h-[50vh] bg-white border-gray-200 flex flex-col">
                    <div class="overflow-y-auto max-h-[100vh] hidden lg:block relative">
                        <tree :tree="tree" v-on:new-active-tab="handleActiveTab" v-on:on-hover-tab="changeHoverText"></tree>
                    </div>
                </div>
            </div>
            <div class="w-px h-64 bg-black cursor-ew-resize hidden lg:block"></div>
            <tab-manager ref="tabManager" class="bg-gray-200 border-2 border-gray-400 grow relative min-h-[50vh]"
                @next="handleNext($event)" @back="handleBack($event)" @new-active-tab="handleActiveTab"></tab-manager>
            </div>
            <!--Information project-->
            <div class="flex flex-col border-t border-gray-300 bg-gray-300">
                <div class="flex justify-between text-sm px-2 py-1 gap-1 ">
                    <div class="flex-grow border border-gray-400 p-1 ">3 proyectos</div>
                    <div class="border border-gray-400 w-32 p-1">32kb</div>
                    <div class="flex-grow border border-gray-400 p-1">0.3s</div>
                </div>
                <div class="text-sm px-2 py-1 border border-gray-400 m-2">
                    {{ hoverText }}
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    props:{
        treeProp: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            tree: {},
            isMobile: false,
            showMenu: false,
            width: 30,
            hoverText: "Texto cuando el cursor este encima..."
        }
    },
    mounted() {
        this.tree = this.treeProp;
        if (window.innerWidth < 1024) {
            this.width = 100; 
            this.showMenu = false; 
            this.isMobile = true; 
        }

        window.addEventListener('resize', () => {
            if (window.innerWidth < 1024) { 
                this.width = 100;
                this.showMenu = false; 
                this.isMobile = true; 
            } else {
                this.width = 30;
                this.isMobile = false;
            }
        });

        setTimeout(()=>{
            if (this.tree.children[0]  && window.location.hash == ""){ 
                let first = this.tree.children[0];
                // open a random tab
                let webs = this.dfs(this.tree, {id: "webs"});
                this.handleActiveTab(webs);
            }
        }, 1000)

        
    },
    methods: {
        handleActiveTab(treeItem) {
            this.showMenu = false;
            if (treeItem.next == null){
                treeItem.next = this.dfsNext(this.tree, treeItem)
            }
            let found = this.dfs(this.tree, treeItem);
            found.active = true;
            this.$refs.tabManager.newTab(treeItem);
            if (treeItem.back == null){
                treeItem.back = this.dfsBack(this.tree, treeItem)
            }
            this.$refs.tabManager.newTab(found);
            // set hash to active tab
            if (window.location.hash != treeItem.id){
                window.location.hash = treeItem.id;
                // set the actual URL to the active tab
                window.history.replaceState(undefined, undefined, "#" + treeItem.id);
            }
        },
        changeHoverText(tree) {
            this.hoverText = tree.name
        },
        handleNext(treeItem){
            treeItem.next = this.dfsNext(this.tree, treeItem)
            treeItem.back=this.dfsBack(this.tree, treeItem)
            this.$refs.tabManager.newTab(treeItem);
        },
        handleBack(treeItem){
            treeItem.back=this.dfsBack(this.tree, treeItem)
            treeItem.next = this.dfsNext(this.tree, treeItem)
            this.$refs.tabManager.newTab(treeItem);
        },
        dfs(tree, treeItem) {
            if (tree === null || treeItem == null) return null;
            if (tree.id === treeItem.id) {
                return tree;
            }
            for (let i = 0; i < tree.children.length; i++) {
                if (tree.children[i].id === treeItem.id) {
                    return tree.children[i];
                }
                let found = this.dfs(tree.children[i], treeItem);
                if (found) return found;
            }
        },
        dfsNext(tree, treeItem) {
                for (let i = 0; i < tree.children.length; i++) {
                    let child = tree.children[i];

                    if (child.id === treeItem.id) {
                        return i + 1 < tree.children.length ? tree.children[i + 1] : null;
                    }
                    let found = this.dfsNext(child, treeItem);
                    if (found) return found;
                }
            return null; 
        },
        dfsBack(tree, treeItem) {
            for (let i = 0; i < tree.children.length; i++) {
                if (tree.children[i].id === treeItem.id) {
                    return i > 0 ? tree.children[i - 1] : null;
                }

                let found = this.dfsBack(tree.children[i], treeItem);
                if (found) return found;
            }
            return null; 
        }
}

}
</script>