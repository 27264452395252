
// on load


export function setupTransition() {
	let start = Date.now();
	if (localStorage.getItem("wm-transition")) {
		let overlay = document.querySelector("#overlay") as HTMLElement;
		overlay.style.display = "none";
	}
	document.addEventListener("DOMContentLoaded", function () {
		let delay = Date.now()-start > 1000 ? 0: 1000
		setTimeout(() => {
		localStorage.setItem("wm-transition", (new Date()).toISOString());
		let overlay = document.querySelector("#overlay") as HTMLElement;
		if (!overlay) return;
		overlay.style.opacity = "0";
		overlay.style.transform = "translateY(-100%)";
		}, delay)
	})

	window.setTimeout(() => {
		let body = document.querySelector('body')
		if (!body) return;
		if (body.style.opacity === "0") {
			body.style.opacity = "1"
		}
	}, 5000);
}

 // add transitions
 function fancyTransition(){



	

	// add a logo int he center of the overlay
 
	document.body.appendChild(overlay);
 
	// document.querySelectorAll("a").forEach((el) => {  
	//   let href = el.getAttribute("href");
	//   if (!href) {
	// 	console.warn("No href attribute found in the anchor tag", el);
	// 	return;
	//   };
 
	//   // remove the "href"
	//   el.removeAttribute("href");
	//   // add class "cursor-pointer"
	//   el.classList.add("cursor-pointer");
 
	//   el.onclick = (e) => {
	// 	 e.preventDefault()
	// 	 if (href?.includes("#")) {
	// 		console.log("HREF", href)
	// 		return
	// 	 }
	// 	 e.preventDefault();
	// 	 // if it is the same page dont do anything
	// 	 if (href === window.location.href) return;
 
	// 	 overlay.style.opacity = "1"
	// 	 overlay.style.transform = "translateX(0)";
 
	// 	 setTimeout(() => {
	// 		// create a link and click it
	// 		let a = document.createElement("a");
	// 		a.href = href;
	// 		a.click();
	// 	 }, 300)
	//   };
	// });
 }