<template>
	<div class="border p-4 bg-black/50 rounded-md">
		<label for="" class="text-center text-gray-400 text-xs">Edit me: in /src/assets/ts/components/counter.vue</label>
		<h1 class="text-5xl mt-4">{{ count }}</h1>
		<button class="btn btn-primary mt-4"  @click="increment">Increment</button>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'Counter',
	data() {
		return {
			count: 0
		};
	},
	methods: {
		increment() {
			this.count++
		}
	}
});
</script>

<style scoped>
button {
	padding: 10px 20px;
	font-size: 16px;
}
</style>
