import { createApp } from 'vue/dist/vue.esm-bundler.js';
import Counter from './components/counter.vue';
import Tree from './components/tree.vue';
import TreeItem from './components/tree-item.vue';
import Tab from './components/tab.vue';
import TabManager from './components/tab-manager.vue';
import Explorer from './components/explorer.vue';
import Matrix from './components/matrix.vue';

function NewApp() {
  let app = createApp({})
    app.component('counter', Counter);
    app.component('tree', Tree);
    app.component('tree-item', TreeItem);
    app.component('tab', Tab);
    app.component('tab-manager', TabManager);
    app.component('explorer', Explorer);
    app.component('matrix', Matrix);


  // Common
  return app;
}

let lastApp: any = null;

export function initVue(){
  if (window.vm) {
    lastApp?.unmount()
    lastApp = null;
  }
  let app = NewApp();
  window.vm = app.mount("[data-behavior='vue']")
  lastApp = app;
}