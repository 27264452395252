import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "bg-black text-green-600 font-mono break-all h-10 overflow-hidden text-sm" }
const _hoisted_2 = { class: "text-green-500" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.numbers, (number) => {
      return (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(number), 1 /* TEXT */))
    }), 256 /* UNKEYED_FRAGMENT */))
  ]))
}